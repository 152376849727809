import React from 'react';
import { Button, Dialog, DialogContentText, Typography, Box, IconButton, DialogActions } from '@mui/material';
import swiftbot from '../../../assets/swiftbot.png';
import { formatDate } from '../../../utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { setModalClose, setFireAlarmOff, setFireAlarmOn } from '../../../store/fire/fireTriggerSlice';
import { setNotificationOn, setNotificationOff, setNotificationMessage, setNotificationTitle, setNotificationType } from '../../../store/notification/notificationSlice';

const FireAlarmModal = () => {
    const dispatch = useDispatch();
    const isFireTriggerModalOpen = useSelector((state: any) => state.fire.fireTrigger);
    const isFireAlarmOn = useSelector((state: any) => state.fire.isFireAlarmOn);

    const handleFireAlarmOn = () => {
        dispatch(setFireAlarmOn(true));
        dispatch(setModalClose());

        dispatch(setNotificationOn());
        dispatch(setNotificationType("fire"));
        dispatch(setNotificationTitle("Fire Alarm Triggered"));
        dispatch(setNotificationMessage("Robot Operations have been halted"));
    }



    return ( 
        <div>
            <Dialog
                open={isFireTriggerModalOpen}
                onClose={() => dispatch(setModalClose())}
                maxWidth={false}
                sx={{
                    '& .MuiDialog-paper': {
                        width: '600px',
                        height: '130px',
                        bgcolor: 'transparent',
                        boxShadow: 'none',
                        position: 'absolute',  
                        top: '10%',  
                        left: '50%', 
                        transform: 'translate(-50%, -10%)',  
                        borderRadius: "10px"
                    }
                }}
            >
                <Box style={{
                    // border: "10px solid #ff0000",
                    borderRadius: "10px",
                    backgroundColor: "#ffffff"
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        margin: 'auto',
                        bgcolor: "#fff",
                        p: 2,
                    }}>
                        <IconButton
                            onClick={() => { dispatch(setModalClose()) }}
                            sx={{
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                color: "#727272"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContentText sx={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#000",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            mt:1
                        }}>
                            Trigger a Fire Event?
                            <Typography variant="h5" component="div" sx={{
                                color: "#727272",
                                fontSize: "14px",
                                textAlign: "center",
                                mt: 1
                            }}>
                                AMR will automatically move to a safe parking location
                            </Typography>
                        </DialogContentText>
                    
                    </Box>
                    <Button fullWidth={true} variant="contained" sx={{ backgroundColor: "#12B76A", color: "#fff", borderRadius: "0px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", boxShadow:"none", '&:hover': { backgroundColor: "#13854f" } }} onClick={handleFireAlarmOn}>
                        Confirm
                    </Button>
                </Box>
            </Dialog>
        </div>
    );
}
 
export default FireAlarmModal;
import TopBar from "../../components/layouts/Topbar";
import { Box, Button, Grid, Typography, Stack, TextField, Menu, MenuItem, Divider } from "@mui/material";
import AggregateCard from "./components/AggregateCard";
import parcel from '../../assets/parcel.png';
import MenuIcon from '@mui/icons-material/Menu';
import DeliveryBoard from "./components/DeliveryBoard";
import RobotIcon from "../../components/icons/RobotIcon";
import RobotStatus from "./components/RobotStatus";
import { cardDetails, mapDetails, generalStatus } from "../../utils/DummyData";
import LocationMarker from "./components/LocationMarker";
import Fuse from "fuse.js";
import JobBoard from "./components/JobBoard";
import { JobEnums } from "../../utils/enums/JobEnums";
import RobotMapContainer from "./components/RobotMapContainer";
import RobotList from "./components/RobotList";
import GeneralStatus from "./components/GeneralStatus";
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useWebSocket } from "../../components/useWebSocket";
import axios from "axios";
import { Robot } from "../../components/RobotMap/models/Robot";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import salad from '../../../assets/salad.png'; 
import NotificationHistoryPanel from "./components/NotificationHistoryPanel";
import NotificationPopUp from "./components/NotificationPopUp";
import Sidebar from "../../components/layouts/Sidebar";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import {toggle, setTrue, setFalse} from "../../store/deliveryDashboardSlice";
import { storeMapPoints, zoomIn, zoomOut, setPickUpSelected, setDropOffSelected, setSenderLocation, setRecipientLocation, setSenderAndRecipientSelected } from "../../store/map/mapSlice";
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import { toggleLegend } from "../../store/map/toggleLegendSlice";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { triggerFire } from "../../store/fire/fireTriggerSlice";
import Notification from "./components/Notification";
import FireAlarmModal from "./components/FireAlarmModal";
import { setIsSelectedRobot, setSelectedRobot, updateJobData, updateRobotStatus } from "../../store/robot/robotSlice";
import { setIsJobSelected, setSelectedJob, updateJob } from "../../store/job/jobSlice";
import { updateField, addRecipientLocation, updateRecipientLocation, removeRecipientLocation, setRecipientLocationInForm, updateRecipientIndex, deleteRecipientLocation } from "../../store/job/formSlice";
import { setNotificationMessage, setNotificationOn, setNotificationTitle, setNotificationType } from "../../store/notification/notificationSlice";
import { setSearchQuery } from "../../store/job/searchSlice";
import { addRobot, updateRobot } from "../../store/robot/robotSlice";
import { MapDetails3, JobData2, JobDetails3, RobotData3, RobotInfo3 } from "../../store/types";
import { setTotalJobNumber, setTotalPageNumber, setCurrentPageNumber, setJobsPerPage, setCurrentRobotJobsPageNumber, setTotalRobotJobs, setTotalRobotJobsPageNumber  } from "../../store/page/jobPageSlice";
interface CardData {
    title: string;
    data: string;
}

interface formDataType {
    pickUp: string;
    dropOff: string;
    time: string;
    robot: string;
    waitTime: string;
    phoneNumber: string;

    recipient_name: string;
    recipient_location: string;
    compartment_pin: string;
    sender_location: string;
    sender_name: string;
    job_type: string;
    robot_id: string;
    required_compartment: number;
    timestamp: number;
    sender_id: string;
    recipient_id: string;
    organisation_id: string;
}


interface Pose2 {
    latitude: number;
    longitude: number;
    theta: number;
    z: number;
}

interface MapDetails2 {
    building: string;
    location_name: string;
    map: string;
    map_name: string;
    pose: Pose2;
    store_manager_id: string;
}


interface SearchQuery {
    pickUpResults: MapDetails3[];
    dropOffResults: MapDetails3[];
}


interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}


interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}
interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
}


interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [order_number: string]: JobDetails2;
}


const defaultJob: JobDetails2 = {
    compartment_pin: "",
    id: 0,
    job_type: "",
    job_id: 0,
    organisation_id: "",
    load_compartment: 0,
    recipient_id: "",
    recipient_location: "",
    recipient_name: "",
    robot_id: "",
    sender_id: "",
    sender_location: "",
    sender_name: "",
    timestamp: 0,
    order_number: "",
    job_status: ""
}

const defaultRobot: RobotInfo = {
    robotStatus: {
        battery: 0,
        can_assign_job: true,
        is_online: true,
        map: "",
        organisation: "",
        pose: {
            latitude: 1.2973538783600418,
            longitude: 103.78681142555857,
            orientation: 0
        },
        robot_id: "",
        robot_state: "",
        robot_type: "",
        timestamp: 0,
        robot_name: "",
        robot_serial_number: ""
    },
    jobs: {},
    generalInfo: {
        robot_name: "",
        robot_mac: "",
        robot_serial_number: "",
        robot_type: "",
        organisation: ""
    }
};
const defaultRobot2: RobotInfo3 = {
    robotStatus: {
        battery: 0,
        can_assign_job: true,
        is_online: true,
        map: "",
        organisation: "",
        pose: {
            latitude: 1.2973538783600418,
            longitude: 103.78681142555857,
            orientation: 0
        },
        robot_id: "",
        robot_state: "",
        robot_type: "",
        timestamp: 0,
        robot_name: "",
        robot_serial_number: ""
    },
    jobs: {},
    generalInfo: {
        robot_name: "",
        robot_mac: "",
        robot_serial_number: "",
        robot_type: "",
        organisation: ""
    }
};
interface DashboardProps {
    onNotificationCountChange: (count: number) => void;
}

const Dashboard: React.FC <DashboardProps> = ({ onNotificationCountChange }) => {
    const currentDropOffIndex = useSelector((state: any) => state.form.current_index);
    const dispatch = useDispatch();
    const isOpen = useSelector((state: any) => state.deliveryDashboard.isOpen);
    const zoomLevel = useSelector((state: any) => state.map.zoomLevel);
    const legendOpen = useSelector((state: any) => state.toggleLegend.isLegendOn);
    const triggerFireState = useSelector((state: any) => state.fire.fireTrigger);
    const isNotificationOn = useSelector((state: any) => state.notification.isNotificationOn);
    const searchQuery = useSelector((state: any) => state.search.searchQuery);

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const audioSrc = require('../../assets/notificationSound.mp3'); // Make sure the path is correct
    const audioRef = useRef<HTMLAudioElement>(null);
    const [todayJobs, setTodayJobs] = useState<JobData>({});

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
        return;
      }
      setIsDrawerOpen(open);
    };
    
    const [latestNotification, setLatestNotification] = useState<JobDetails2>(defaultJob);
    const [latestNotificationId, setLatestNotificationId] = useState<string>("");
    const [notificationModalOpen, setNotificationModalOpen] = useState<boolean>(false);

    // useEffect(() => {
    //     if (notificationModalOpen) {
    //         // Try to play audio when modal opens
    //         console.log("Playing audio...", audioRef.current);
    //         audioRef.current?.play().catch(err => {
    //             console.error("Playback prevented:", err);
    //             // Handle or log error here
    //         });
    //     }
    // }, [notificationModalOpen]);
    
    const handleNewNotification = (notification: JobDetails2) => {
        const newNotificationHistory = { ...dummyNotificationHistory, [notification.order_number]: notification };
        setDummyNotificationHistory(newNotificationHistory);
        setLatestNotificationId(notification.order_number); // Assuming order_number is unique
        setNotificationModalOpen(true); // Open the modal
    };
    
    const handleCloseNotificationModal = () => {
        setNotificationModalOpen(false);
        setLatestNotificationId("");
    };
    
    
    const profile = JSON.parse(localStorage.getItem('profile') || '{}');
    const accessToken = localStorage.getItem('accessToken');
    const [listOfLocations, setListOfLocations] = useState<MapDetails2[]>([]);
    const [listOfLocations2, setListOfLocations2] = useState<MapDetails3[]>([]);

    const [robots, setRobots] = useState<RobotsData>({});
    const [robots3, setRobots3] = useState<RobotData3>({});
    
    const robots2 = useSelector((state: any) => state.robot.robots);
    const [jobs, setJobs] = useState<JobData>({});
    const [jobs3, setJobs3] = useState<JobData2>({});
    const [prevRobotPose, setPrevRobotPose] = useState<[number, number]>([0, 0]);
    const [curRobotPose, setCurRobotPose] = useState<[number, number]>([0, 0]);

    const [jobId, setJobId] = useState<number>(0);
    const [dummyNotificationHistory, setDummyNotificationHistory] = useState<JobData>({});
    // const { messages, sendMessage } = useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${profile.uuid}/`)

    const jobOverviewUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${profile.uuid}/`;
    const [notificationCount, setNotificationCount] = useState<number>(0);

    useEffect(() => {
        console.log("checking listoflocation2: ", listOfLocations2);

    }
    , [listOfLocations2]);
    
    const jobs2 = useSelector((state: any) => state.job.jobs);
    const handleMessage = useCallback((data: any) => {
        let job = data;
        console.log("Job data:", job);

        const updateJobs = (jobs: JobData, newJob: JobDetails2) => {
            const updatedJobs = {
                ...jobs,
                [newJob.order_number]: newJob
            };
            const sortedJobKeys = Object.keys(updatedJobs)
                .sort((a, b) => updatedJobs[b].timestamp - updatedJobs[a].timestamp); // Sort by most recent first
            if (sortedJobKeys.length > 10) {
                sortedJobKeys.length = 10; // Keep only the 10 most recent jobs
            }
      
            return sortedJobKeys.slice(0, 10).reduce<JobData>((acc, key) => {
                acc[key] = updatedJobs[key];
                return acc;
            }, {});
        };
        // Update job state or add new job
        // setJobs(prevJobs => ({
        //     ...prevJobs,
        //     [job.order_number]: job
        // }));
        
        // setJobs(prevJobs => {
        //     const updatedJobs: JobData = {
        //         ...prevJobs,
        //         [job.order_number]: job
        //     };
        //     const sortedJobKeys = Object.keys(updatedJobs)
        //       .sort((a, b) => updatedJobs[b].timestamp - updatedJobs[a].timestamp);
            
        //     if (sortedJobKeys.length > 10) {
        //       sortedJobKeys.length = 10; // Keep only the 10 most recent jobs
        //     }
      
        //     return sortedJobKeys.reduce<JobData>((acc, key) => {
        //       acc[key] = updatedJobs[key];
        //       return acc;
        //     }, {});
        //   });
        setJobs(prevJobs => updateJobs(prevJobs, job));
        setTodayJobs(prevJobs => updateJobs(prevJobs, job));
        //   setTodayJobs(prevJobs => {
        //     const updatedJobs: JobData = {
        //         ...prevJobs,
        //         [job.order_number]: job
        //     };
        //     const sortedJobKeys = Object.keys(updatedJobs)
        //       .sort((a, b) => updatedJobs[b].timestamp - updatedJobs[a].timestamp);
            
        //     if (sortedJobKeys.length > 10) {
        //       sortedJobKeys.length = 10; // Keep only the 10 most recent jobs
        //     }
      
        //     return sortedJobKeys.reduce<JobData>((acc, key) => {
        //       acc[key] = updatedJobs[key];
        //       return acc;
        //     }, {});
        //   });

        setRobots(prevRobots => {
            const robotId = job.robot_id;
            const newJobId = job.order_number;
            const newJobData = data;
            console.log("Robot ID:", robotId);
            console.log("Job ID:", newJobId);
            console.log("New Job Data:", newJobData);
            
            if (robotId in prevRobots) {
                return {
                    ...prevRobots,
                    [robotId]: {
                        ...prevRobots[robotId],
                        jobs: {
                            ...prevRobots[robotId].jobs,
                            [newJobId]: job
                        }
                    }
                };
            } else {
                return prevRobots;
            }
        });
        

        dispatch(updateJob({ [job.order_number]: job }));

        // update job relevant to robot
        if (job.job_status === JobEnums.ARRIVED_AT_DELIVERING ) {
            setDummyNotificationHistory(prevState => ({
                ...prevState,
                [job.order_number]: job
            }));
            setLatestNotificationId(job.order_number);
            dispatch(setNotificationOn());
            dispatch(setNotificationType("Job Update"));
            // last 5 characters of order number
            dispatch(setNotificationTitle(`Order #${job.order_number.slice(-5).toUpperCase()} has arrived`));
            dispatch(setNotificationMessage(`Please attend to the robot and receive the item at the ${job.recipient_location}`));
        }

        if (job.job_status === JobEnums.ITEM_DELIVERED) {
            setDummyNotificationHistory(prevState => ({
                ...prevState,
                [job.order_number]: job
            }));
        }

        if (data.robot_id) {
            dispatch(updateJobData({ [job.order_number]: job }));
        }
    },[]);

    const handleRobotStatus = useCallback((data: any) => {
        // Update robot state
        setRobots(prevRobots => ({
            ...prevRobots,
            [data.robot_id]: {
                ...prevRobots[data.robot_id],
                robotStatus: {
                    ...prevRobots[data.robot_id]?.robotStatus,
                    ...data,
                    timestamp: Date.now()  // Ensuring timestamp is updated
                }
            }
        }));

        setRobots3(prevRobots => ({
            ...prevRobots,
            [data.robot_id]: {
                ...prevRobots[data.robot_id],
                robotStatus: {
                    ...prevRobots[data.robot_id]?.robotStatus,
                    ...data,
                    timestamp: Date.now()  // Ensuring timestamp is updated
                }
            }
        }));
        // dispatch (updateRobot(robots));
        dispatch(updateRobotStatus(data));

        console.log("Robot pose:", data.pose);
        setPrevRobotPose(curRobotPose);
        setCurRobotPose([data.pose.longitude, data.pose.latitude]);
    }, []);

    useEffect(() => {
        console.log("Robot Jobs:", robots);
        if (selectedRobot3 && selectedRobot3.robotStatus?.robot_id) {
            console.log("Selected Robot:", selectedRobot3);
            setSelectedRobot3(robots[selectedRobot3.robotStatus.robot_id]);
        }
    }, [robots]);
    // if job related to robot is being updated or added, update the robot state
    


    useEffect(() => {
        if (latestNotificationId !== "") {
            console.log("Latest Notification:", latestNotificationId);
            setNotificationModalOpen(true);
        }
    }, [latestNotificationId]);


    useEffect(() => {
        console.log("Jobs Dashboard:", jobs);
        if (selectedJob2 && selectedJob2.order_number !== "") {
            console.log("Selected Job:", selectedJob2);
            setSelectedJob2(jobs[selectedJob2.order_number]);
        }
        
    }, [jobs]);

    useEffect(() => {
        console.log("Dummy Notification History:", dummyNotificationHistory);
        setNotificationCount(Object.keys(dummyNotificationHistory).length);
        onNotificationCountChange(Object.keys(dummyNotificationHistory).length);
    }
    , [dummyNotificationHistory]);

    // useEffect(() => {
    //     console.log("job overview url:", jobOverviewUrl);
    //     console.log("profile:", `${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${profile.uuid}/`);
    //     console.log("robots overview url:", `${process.env.REACT_APP_WEBSOCKET_URL}/robot/${profile.organisation}/`);
    // }, []);
    useWebSocket(jobOverviewUrl, handleMessage);
    useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/robot/${profile.organisation}/`, handleRobotStatus);

    const contentRef = useRef<HTMLDivElement>(null);
    const [imageHeight, setImageHeight] = useState<number>(0);
    const [timeFocused, setTimeFocused] = useState<boolean>(false);
    // const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isPickUpFocused, setIsPickUpFocused] = useState<boolean>(false);
    const [isDropOffFocused, setIsDropOffFocused] = useState<boolean>(false);

    const [fieldCompleted, setFieldCompleted] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [showRobotStatus, setShowRobotStatus] = useState<boolean>(false);

    const [isJobTab, setIsJobTab] = useState<boolean>(false);
    const [showRobotList, setShowRobotList] = useState<boolean>(false);

    const [showGeneralStatus, setShowGeneralStatus] = useState<boolean>(false);

    // const [selectedRobot, setSelectedRobot] = useState<Robot>(new Robot("", initialLatLng, robotDetails));
    const [selectedRobot3, setSelectedRobot3] = useState<RobotInfo>(defaultRobot);


    const [lastActiveComponent, setLastActiveComponent] = useState('');
    const [searchValue, setSearchValue] = useState("");

    const [form, setForm] = useState<formDataType>({
        pickUp: "#01-07",
        dropOff: "",
        time: "Pick Up Now",
        robot: "Call Nearest Robot",
        waitTime: "10mins",
        phoneNumber: "",

        recipient_name: "Kelvin",
        recipient_location: "",
        compartment_pin: "0000",
        sender_location: "Concierge",
        sender_name: `${profile.username}`,
        // job_type: "pick_up_job", //delivery_job
        job_type: "delivery_job",
        // robot_id: "2050E7403412",
        robot_id: "",
        required_compartment: 1,
        timestamp: Date.now(),
        sender_id: `${profile.uuid}`,
        // sender_id: "",
        recipient_id: "",
        organisation_id: `${profile.organisation}`
    });


    const [selectedJob2, setSelectedJob2] = useState<JobDetails2>(defaultJob);
    // const [selectedJob3, setSelectedJob3] = useState<JobDetails3>(defaultJob2);
    // const [searchResults, setSearchResults] = useState<Location[]>([]);
    const [searchResults, setSearchResults] = useState<SearchQuery>({ pickUpResults: [], dropOffResults: [] });
    const [activeStep, setActiveStep] = useState(0);



    useEffect(() => {
        if (showGeneralStatus){
            setShowRobotList(false);
            setShowRobotStatus(false);
            setIsJobTab(false);
        }

    }, [showGeneralStatus]);

    useEffect(() => {
        if (showRobotList || showRobotStatus || isJobTab){
            setShowGeneralStatus(false);
        }
        if (isJobTab){
            getJobList();
        }
    }, [showRobotList, showRobotStatus, isJobTab]);
    
    const handleShowGeneralStatus = () => {
        setShowGeneralStatus(!showGeneralStatus);
    }

    
    const handleSelectedJob2 = (job: JobDetails2) => {
        console.log("Selected Job:", job.job_id)
        // setJobId(job.job_id);
        setSelectedJob2(job);
        setSearchResults({ pickUpResults: [], dropOffResults: [] });
        console.log(job);
        setIsJobTab(true);
        
        // dispatch(setSelectedJob(job));
        // setSelectedJob3(job);
        dispatch(setIsJobSelected(true));
        
        if (showRobotStatus) {
            setShowRobotStatus(false);
            setIsJobTab(true);
            setLastActiveComponent("RobotStatus");
        }
        if (isJobTab) {
            setLastActiveComponent("JobBoard");
        }
    }

    useEffect(() => {
        console.log("Selected Job:", selectedJob2);
    }, [selectedJob2]);


    const fuse = new Fuse(listOfLocations2, {
        keys: ['location_name'],
        threshold: 0.3
    });

    const searchLocations = (query: string) => {
        console.log("Searching locations...", form.sender_location, form.recipient_location);
        if (query.length > 0) {
            const results = fuse.search(query);
            const filteredResults = results.map(result => result.item);
            console.log("Filtered results:", filteredResults);
            console.log("Query:", query);
            if (isPickUpFocused) {
                setSearchResults({ pickUpResults: filteredResults, dropOffResults: [] });
                console.log("Pickup focused", filteredResults);
                
            } else if (isDropOffFocused) {
                setSearchResults({ pickUpResults: [], dropOffResults: filteredResults });
                console.log("Dropoff focused", filteredResults);
            }
        } else {
            if (isPickUpFocused) {
                setSearchResults({ pickUpResults: listOfLocations2, dropOffResults: [] });
            } else if (isDropOffFocused) {
                setSearchResults({ pickUpResults: [], dropOffResults: listOfLocations2});
            }
        }
    };
    

    useEffect(() => {
        if (isSubmitted) {
            dispatch(setSenderLocation(""));
            dispatch(setRecipientLocation(""));
            console.log(form, "Form submitted");

            console.log("Resetting form...");
            resetFocus();
            // reset form
            setForm ({
                pickUp: "#01-07",
                dropOff: "",
                time: "Pick Up Now",
                robot: "Call Nearest Robot",
                waitTime: "10mins",
                phoneNumber: "",

                recipient_name: profile.username,
                recipient_location: "",
                compartment_pin: "0000",
                sender_location: "Concierge",
                sender_name: "Bryan",
                job_type: "delivery_job",
                robot_id: "",
                required_compartment: 1,
                timestamp: Date.now(),
                sender_id: `${profile.uuid}`,
                // sender_id: "",
                recipient_id: "",
                organisation_id: `${profile.organisation}`
            });
            setFieldCompleted(false);
        }
    }, [isSubmitted]);

    const handleRecipientLocationChange = (value: string, index: number) => {
        console.log("Recipient Location Change", value, index);
        dispatch(updateRecipientLocation({ index, value }));  
        searchLocations(value);
    };

    const assignJob = async (event: React.FormEvent<HTMLFormElement>) => {
        console.log("form", form);
        event.preventDefault();
        let apiURL = `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job/schedule_robot/`;
        if (form.robot_id !== "") {
            console.log("Assigning job to robot...");
            apiURL = `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job/assign_robot/`;
            
        }
        await axios({
            method: "POST",
            // url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/order/submit_order/`,
            url: apiURL,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            },
            data: form,
          })
          .then ((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("starting job", response.data);
                setIsSubmitted(true);
                // setSelectedJob2(response.data);
                // setSelectedJob3(response.data);
                setIsJobTab(true);
                dispatch(setSelectedJob(response.data));
                dispatch(setIsJobSelected(true));
                setTimeout(() => {
                    setIsSubmitted(false);
                    dispatch (setFalse());
                }, 5000);

            } 
          })
          .catch(function (error) {
            console.log("error", error.response.data);
          });
    }

    const queryMapLocations = async () => {
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/location/`,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                // setListOfLocations(response.data);
                setListOfLocations2(response.data.data);
                dispatch(storeMapPoints(response.data.data));
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }

    const robotJobPageNumber = useSelector((state: any) => state.jobPage.currentRobotJobsPageNumber);

    const getRobotJobs = async (robotId: string) => {
        console.log("Getting robot jobs...", robotId);
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/assigned_job/?robot_id=${robotId}&page=${robotJobPageNumber}&page_size=10`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response robots", response.data);
                const robotJobs = response.data.data.reduce((acc: JobData, job: JobDetails2) => {
                    acc[job.order_number] = job;
                    return acc;
                }
                , {});
                dispatch ( setCurrentRobotJobsPageNumber(response.data.current_page));
                dispatch ( setTotalRobotJobs(response.data.count));
                dispatch ( setTotalRobotJobsPageNumber(response.data.num_pages));

                setRobots(prevRobots => ({
                    ...prevRobots,
                    [robotId]: {
                        ...prevRobots[robotId],
                        jobs: robotJobs // Replacing the existing jobs
                    }
                }));

                // setListOfLocations(response.data);
                // setListOfLocations2(response.data.data);
                // dispatch(storeMapPoints(response.data.data));
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (selectedRobot3 && selectedRobot3.robotStatus?.robot_id) {
            console.log("Selected Robot:", selectedRobot3);
            getRobotJobs(selectedRobot3.robotStatus.robot_id);
        }
    }, [robotJobPageNumber]);

    const getRobotStatus = async () => {
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot_status/?organisation=${profile.organisation}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                response.data.map((robot: any) => {
                    setRobots (prevRobots => ({
                        ...prevRobots,
                        [robot.robot_id]: {
                            ...prevRobots[robot.robot_id],
                            robotStatus: robot
                            
                        }
                    }));
                });
                // setListOfLocations(response.data);
                // setListOfLocations2(response.data.data);
                // dispatch(storeMapPoints(response.data.data));
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }
    const pageNumber = useSelector((state: any) => state.jobPage.currentPageNumber);
    const pageSize = useSelector((state: any) => state.jobPage.jobsPerPage);

    useEffect(() => {
        getJobList();
    }, [pageNumber]);
    const getJobList = async () => {
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/?page=${pageNumber}&page_size=10`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                dispatch(setTotalJobNumber(response.data.count));
                dispatch(setTotalPageNumber(response.data.num_pages));
                dispatch(setCurrentPageNumber(response.data.current_page));
                const jobsData = response.data.data.reduce((acc: JobData, job: JobDetails3) => {
                    acc[job.job_id] = {
                        compartment_pin: job.compartment,
                        id: 0,
                        job_type: job.job_type,
                        job_id: 0,
                        organisation_id: "",
                        load_compartment: 0,
                        recipient_id: "",
                        recipient_location: job.to_location,
                        recipient_name: "",
                        robot_id: job.robot_id,
                        sender_id: "",
                        sender_location: job.from_location,
                        sender_name: "",
                        timestamp: job.created_time,
                        order_number: job.job_id,
                        job_status: job.status
                    };
                    return acc;
                }, {});

                setJobs(jobsData);


                const todayJobsData = response.data.data.reduce((acc: JobData, Job: JobDetails3) => {
                    // if today job, add to today job
                    const today = new Date();
                    const todayDate = today.getDate();
                    const todayMonth = today.getMonth();
                    const todayYear = today.getFullYear();
                    const createdDate = new Date(Job.created_time*1000);
                    const createdDay = createdDate.getDate();
                    const createdMonth = createdDate.getMonth();
                    const createdYear = createdDate.getFullYear();
                    console.log("Today's Date:", todayDate, todayMonth, todayYear, "Created Date:", createdDay, createdMonth, createdYear);

                    if (todayDate === createdDay && todayMonth === createdMonth && todayYear === createdYear) {
                        console.log("Today's Job:", Job.job_id);
                        acc[Job.job_id] = {
                            compartment_pin: Job.compartment,
                            id: 0,
                            job_type: Job.job_type,
                            job_id: 0,
                            organisation_id: "",
                            load_compartment: 0,
                            recipient_id: "",
                            recipient_location: Job.to_location,
                            recipient_name: "",
                            robot_id: Job.robot_id,
                            sender_id: "",
                            sender_location: Job.from_location,
                            sender_name: "",
                            timestamp: Job.created_time,
                            order_number: Job.job_id,
                            job_status: Job.status
                        };
                    }
                    return acc;
                }, {});

                setTodayJobs(todayJobsData);

                // setListOfLocations(response.data);
                // setListOfLocations2(response.data.data);
                // dispatch(storeMapPoints(response.data.data));
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }


    useEffect (() => {
        console.log("Today's Jobs:", todayJobs);
        
    }, [todayJobs]);

    const queryRobots = async () => {
        try {
            // const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot/?robot_organisation__uuid=${profile.organisation}`, {
            //     headers: {
            //         "Authorization": `Bearer ${accessToken}`,
            //     },
            // });
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot/??page_size=1`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                },
            });
            console.log("new robot data", data);
            const robotsData: RobotsData = data.data.reduce((acc: RobotsData, robot: any) => {
                const { robot_mac, robot_name, robot_serial_number, robot_type, organisation } = robot;
                // Initialise robot data
                acc[robot_mac] = {
                    robotStatus: {
                        robot_name: robot_name,
                        robot_serial_number: robot_serial_number,
                        battery: 0,
                        can_assign_job: true, 
                        is_online: true, 
                        map: "",
                        organisation: organisation,
                        pose: {
                            latitude: 1.2979178887706837,
                            longitude: 103.78740445384005,
                            orientation: 0 
                        },
                        robot_id: robot_mac,
                        robot_state: "Idle",
                        robot_type: robot_type.toString(),
                        timestamp: Date.now(), 
                    },
                    jobs: {},
                    generalInfo: {
                        robot_name: robot_name,
                        robot_mac: robot_mac,
                        robot_serial_number: robot_serial_number,
                        robot_type: robot_type.toString(),
                        organisation: organisation
                    }
                };
                return acc;
            }, {});

            dispatch(addRobot(robotsData));
    
            setRobots(robotsData); 
        } catch (error) {
            console.error("Failed to fetch robots:", error);
        }
    };
    
    useEffect(() => {
        queryMapLocations();
        queryRobots();
        getRobotStatus();
        getJobList();
    }, []);


    const handleTimeFocus = () => {
      setTimeFocused(true);
    };

    const handleTimeBlur = () => {
        console.log("Time blurred", form.time);
        setTimeFocused(false);
    };

    const handleIsOpen = () => {
        if (isSubmitted) {
            setIsSubmitted(false);
        }
        // setSelectedJob2(defaultJob);
        setIsJobTab(false);
        setShowRobotList(false);
        // dispatch( toggle(!isOpen));
        dispatch(toggle());
        // setIsOpen(!isOpen);
    }

    const handleStartDeliveryFocus = () => {
        setIsPickUpFocused(true);
        setIsDropOffFocused(false);
        console.log("Pickup focused");
    };

    const handleStartDeliveryBlur = (index:number) => {
        setIsDropOffFocused(true);
        setIsPickUpFocused(false);
        dispatch (updateRecipientIndex(index));
        console.log("Pickup blurred");
    }

    const resetFocus = () => {
        setIsPickUpFocused(false);
        setIsDropOffFocused(false);
    }
    

    // const handleLocationSelect = (location: string, deliveryType: string) => {
    //     deliveryType === "Pick Up" ? setForm(prevState => ({...prevState, sender_location: location})) : setForm(prevState => ({...prevState, recipient_location: location}));
    //     resetFocus();
    //     if (isOpen == false) {
    //         setIsOpen(true);
    //     }
    // };

    const senderLocation = useSelector((state: any) => state.map.senderLocation);
    const recipientLocation = useSelector((state: any) => state.map.recipientLocation);
    const recipient_locations = useSelector((state: any) => state.form.recipient_locations);

    const handleLocationSelect = useCallback((location: string, deliveryType: string) => {
        // deliveryType === "Pick Up"
        //     ? setForm(prevState => ({ ...prevState, sender_location: location }))
        //     : setForm(prevState => ({ ...prevState, recipient_location: location }));
        
        if (deliveryType === "Pick Up") {
            console.log("Updating sender_location in form", location, form.sender_location);
            setForm(prevState => ({ ...prevState, sender_location: location }))
            dispatch (updateField({name: "sender_location", value: location}));
        } 
        else{
            if (currentDropOffIndex === 0) {
                console.log("Updating recipient_location in form", currentDropOffIndex, location, form.recipient_location);
                setForm(prevState => ({ ...prevState, recipient_location: location }));
            }
            dispatch (updateField({name: "recipient_location", value: location}));
        }

        if (deliveryType === "Drop Off") {
            console.log("Updating recipient_location in form", currentDropOffIndex, location, form.recipient_location);
            dispatch ( updateRecipientLocation({index: currentDropOffIndex, value: location}));
        }
        
        resetFocus();
        
        deliveryType === "Pick Up"
            ? dispatch(setSenderLocation(location))
            : dispatch(setRecipientLocation(location));

        senderLocation !== "" && recipientLocation !== "" ? 
        dispatch(setSenderAndRecipientSelected(true)) 
        : dispatch(setSenderAndRecipientSelected(false));
        
        if (!isOpen) {
            // setIsOpen(true);
            dispatch(setTrue());
        }
    }, [setForm, resetFocus, isOpen]);

    const handleJobSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { value } = event.target;
        setSearchValue(value);
    }

    // post to backend
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        console.log("Submitting form...");
        // assignJob(event);
        recipient_locations.map ( (location:string, index:number) => {
            console.log("Recipient Location", index, location);
            setForm (prevState => ({
                ...prevState,
                recipient_location: location
            }));
            // if last index of recipient_locations
            if (index === recipient_locations.length - 1) {
                // reset recipient_locations
                dispatch(deleteRecipientLocation());                
            }
            if (location!== "") {
                assignJob(event);
            }
        }
        );
    };

    const generalInfo: CardData[] = cardDetails;

    useEffect(() => {
        const updateImageHeight = () => {
            const contentHeight = contentRef.current?.offsetHeight ?? 0;
            const viewportHeight = window.innerHeight;
            setImageHeight(viewportHeight - contentHeight - 64);
        };

        updateImageHeight();
        window.addEventListener('resize', updateImageHeight);

        return () => window.removeEventListener('resize', updateImageHeight);
    }, []);

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        let { name, value } = event.target;
        value = name === "time" || name=== "waitTime" || name=== "robot_id" ? event.target.value: value;
        console.log(name, value, "test");
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === "sender_location" || name === "recipient_location") {
            console.log("Searching locations...");
            searchLocations(value);
        }
    }
    
    useEffect(() => {
        if (form.sender_location && form.recipient_location) {
            setFieldCompleted(true);
        }
    }, [form.sender_location, form.recipient_location]);

    useEffect(() => {
        if (isPickUpFocused || isDropOffFocused) {
            searchLocations("");
        }
    }, [isPickUpFocused, isDropOffFocused]);

    const selectedRobot = useSelector((state: any) => state.robot.selectedRobot);

    const onRobotClick2 = (robot: RobotInfo) => {
        
        dispatch (setIsSelectedRobot(true));
        dispatch (setSelectedRobot(robot));
        // console.log("Robot clicked", selectedRobot);
        console.log("Robot clicked", robot);
        setSelectedRobot3(robot);
        setShowRobotStatus(true);
        setShowRobotList(false);
        setLastActiveComponent("RobotStatus");
        setIsJobTab(false);
        if (robot.robotStatus?.robot_id) {
            console.log("Getting robot jobs...", robot.robotStatus.robot_id);
            getRobotJobs(robot.robotStatus.robot_id);
        }
    }

    const onRobotIconClick = (robot: Robot) => {
        dispatch (setIsSelectedRobot(true));
        dispatch (setSelectedRobot(robots[robot.robotId]));

        console.log("Robot clicked", robot.robotId);
        setSelectedRobot3(robots[robot.robotId]);
        setShowRobotStatus(true);
        setShowRobotList(false);
        if (robot.robotId) {
            console.log("Getting robot jobs...", robot.robotId);
            getRobotJobs(robot.robotId);
        }
    }

    const handleJobTab = () => {
        if (showRobotStatus) {
            setShowRobotStatus(false);
            setIsJobTab(true);
            setSelectedRobot3(defaultRobot);
            setLastActiveComponent("RobotStatus");
            setShowRobotList(true);
            dispatch(setIsSelectedRobot(false));
            dispatch(setSelectedRobot(defaultRobot));
        } 
        else {
            setIsJobTab(!isJobTab);
            dispatch(setIsJobSelected(false));
            dispatch(setSelectedJob(defaultJob));
        }
    }

    const handleRobotList = () => {
        if (showRobotStatus || isJobTab) {
            setShowRobotStatus(false);
            setSelectedJob2(defaultJob);
            setShowRobotList(true);
            setSelectedRobot3(defaultRobot);
        } else {
            setShowRobotList(!showRobotList);
        }
    }

    const handleCloseRobotList = () => {
        setShowRobotList(false);
        if (selectedRobot3.robotStatus?.robot_id) {
            //reset selected robot
            setSelectedRobot3(defaultRobot);
        }
        dispatch(setCurrentRobotJobsPageNumber(1));
    }

    const handleCloseRobotStatus = () => {
        setShowRobotStatus(false);
        if (selectedRobot3.robotStatus?.robot_id) {
            //reset selected robot
            setSelectedRobot3(defaultRobot);
            dispatch(setIsSelectedRobot(false));
            dispatch(setSelectedRobot(defaultRobot));
            dispatch(setCurrentRobotJobsPageNumber(1));
        }
        
        setShowRobotList(false);
    }

    const handleJobBoardClose = () => {
        setSearchValue("");
        setIsJobTab(false);
        setSelectedJob2(defaultJob);
        dispatch(setIsJobSelected(false));
        dispatch(setSelectedJob(defaultJob));
        dispatch(setIsSelectedRobot(false));
        dispatch(setCurrentPageNumber(1));
    }

    const handleBackRobotStatus = () => {
        dispatch(setIsSelectedRobot(false));
        dispatch(setSelectedRobot(defaultRobot));
        setShowRobotStatus(false);
        setSelectedRobot3(defaultRobot);
        setShowRobotList(true);
        dispatch(setCurrentRobotJobsPageNumber(1));
    }

    const handleJobBackButton = () => {
        setSelectedJob2(defaultJob);
        if (lastActiveComponent === "RobotStatus") {
            setShowRobotStatus(true);
            setIsJobTab(false);
        } else {
            setShowRobotList(true);
        }
        dispatch(setIsJobSelected(false));
        dispatch(setSelectedJob(defaultJob));
    }

    const handleDeleteNotification = (order_number: string) => {
        console.log("Deleting notification", order_number);
        const updatedNotificationHistory = { ...dummyNotificationHistory };
        delete updatedNotificationHistory[order_number];
        setDummyNotificationHistory(updatedNotificationHistory);
    }

    
    const renderLocationMarkers = () => {
        if (isPickUpFocused) {
            return listOfLocations2.map(location => (
                <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color="#378FFE" handleLocationSelect={() => handleLocationSelect(location.location_name, "Pick Up")} />
            ));
        } else if (isDropOffFocused) {
            return listOfLocations2.map(location => (
                <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color="#FF0000" handleLocationSelect={() => handleLocationSelect(location.location_name, "Drop Off")} />
            ));
        } else if (selectedJob2.sender_location || selectedJob2.recipient_location) {
            console.log("Selected Job showing", selectedJob2);
            return listOfLocations2.filter(location => location.location_name === selectedJob2.sender_location || location.location_name === selectedJob2.recipient_location).map(location => (
                <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color={location.location_name === selectedJob2.sender_location ? "#378FFE" : "#FF0000"} selected={true}/>
            ));
        } 
        // else if (selectedJob2.recipient_location) {
        //     return listOfLocations2.filter(location => location.location_name === selectedJob2.sender_location || location.location_name === selectedJob2.recipient_location).map(location => (
        //         <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color={location.location_name === selectedJob2.sender_location ? "#378FFE" : "#FF0000"} />
        //     ));
        // } 
        else if (form.sender_location || recipient_locations.length > 0) {
            // display only selected locations when neither input is focused
            return listOfLocations2.map(location => (
                <LocationMarker 
                GIS={[location.pose.longitude, location.pose.latitude]} 
                key={location.location_name} 
                name={location.location_name} 
                x={location.pose.longitude} 
                y={location.pose.latitude} 
                color={location.location_name === form.sender_location ? "#378FFE" : "#FF0000"} 
                selected={form.sender_location ===location.location_name || recipient_locations[0] ===location.location_name || recipient_locations[1] ===location.location_name || recipient_locations[2] ===location.location_name }/>
            ));
        }

        else{
            console.log("Displaying all locations");
            return listOfLocations2.map(location => (
                <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color="#378FFE" />
            ));
        }

    };

    // const renderedLocationMarkers = useMemo(() => {
    //     return listOfLocations.map(location => (
    //         <LocationMarker
    //             GIS={[location.pose.longitude, location.pose.latitude]}
    //             key={location.location_name}
    //             name={location.location_name}
    //             x={location.pose.longitude}
    //             y={location.pose.latitude}
    //             color={isPickUpFocused ? "#378FFE" : "#FF0000"}
    //             handleLocationSelect={() => handleLocationSelect(location.location_name, isPickUpFocused ? "Pick Up" : "Drop Off")}
    //         />
    //     ));
    // }, [listOfLocations, isPickUpFocused, isDropOffFocused, handleLocationSelect]);
      

    const handleClearTextField = (field: string) => {
        setForm(prevState => ({
            ...prevState,
            [field]: ""
        }));
        resetFocus();
    }

    const getLines = ():[number, number][] => {
        // check if there is a selected job with both pick-up and drop-off locations
        if (selectedJob2.order_number && selectedJob2.sender_location && selectedJob2.recipient_location) {
            const selectedPickUpLocation = listOfLocations2.find(loc => loc.location_name === selectedJob2.sender_location);
            const selectedDropOffLocation = listOfLocations2.find(loc => loc.location_name === selectedJob2.recipient_location);
            if (selectedPickUpLocation && selectedDropOffLocation) {
                console.log("Displaying line for selected job:", selectedJob2, selectedPickUpLocation, selectedDropOffLocation);
                return [[selectedPickUpLocation.pose.longitude, selectedPickUpLocation.pose.latitude], [selectedDropOffLocation.pose.longitude, selectedDropOffLocation.pose.latitude]];
                // return [selectedPickUpLocation.GIS, selectedDropOffLocation.GIS];
            }
        }
    
        // check if the form's pick-up and drop-off fields are filled and no selected job is active
        if (form.sender_location && form.recipient_location && !selectedJob2.order_number) {
            console.log("updating line for form data:", form);
            const pickUpLocation = listOfLocations2.find(loc => loc.location_name === form.sender_location);
            const dropOffLocation = listOfLocations2.find(loc => loc.location_name === form.recipient_location);
            if (pickUpLocation && dropOffLocation) {
                console.log("Displaying line for form data:", pickUpLocation, dropOffLocation);
                const pickUpGIS = [pickUpLocation.pose.longitude, pickUpLocation.pose.latitude] as [number, number];
                const dropOffGIS = [dropOffLocation.pose.longitude, dropOffLocation.pose.latitude] as [number, number];
                // return [pickUpLocation.GIS, dropOffLocation.GIS];
                return [pickUpGIS, dropOffGIS];
            }
        }
    
        // If no conditions are met, return an empty array
        console.log("No valid data for displaying line");
        return [];
    };
    
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div style={{ color: "white", margin: 0, width: "100%", background: "#f6f7f6", overflow:"hidden" }}>
            <div ref={contentRef}>
                <Stack direction="column" spacing={2} sx={{ width: "100%", margin: "auto", display: "flex", background: "linear-gradient(to bottom, #DAFBEF, #F8F7F7)", height:"120px", alignItems:"center", }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "97%" }}>
                        {/* <MenuIcon sx={{ color: "#9A9A9A", fontSize: "25px", mt:2.5, ml:2 }} /> */}
                        {/* <TopBar isOpen={isOpen} handleIsOpen={handleIsOpen} handleNotificationDisplay={() => setIsDrawerOpen(true)} notificationCount={notificationCount} toggleSidebar={toggleSidebar} /> */}
                    </Box>
                    <Grid container spacing={2} sx={{display:"flex", width:"97%", }}>
                        {generalInfo.map((item) => (
                            <AggregateCard title={item.title} data={item.data} clicked={false} />
                        ))}
                    </Grid>  
                </Stack>  
            </div>
            <Stack direction="column" spacing={2} >
                <div style={{ position: 'relative', width: '100%', height: imageHeight }}>
                    <Box
                        sx={{
                            width: "100%",
                            margin: "auto",
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                        }}
                    >
                        <RobotMapContainer onRobotClick={onRobotIconClick}   line={getLines()} movement={[prevRobotPose, curRobotPose]} robotData={robots} renderLocationMarkers={renderLocationMarkers} >
                            {renderLocationMarkers()}
                            {/* {renderedLocationMarkers} */}
                        </RobotMapContainer>

                    </Box>
                </div>
                {isOpen && (
                    <DeliveryBoard 
                        timeFocused={timeFocused}
                        handleTimeFocus={handleTimeFocus}
                        handleTimeBlur={handleTimeBlur}
                        handleIsOpen={handleIsOpen}
                        handleStartDeliveryFocus={handleStartDeliveryFocus}
                        handleStartDeliveryBlur={(index:number) => handleStartDeliveryBlur(index)}
                        isPickUpFocused={isPickUpFocused}
                        isDropOffFocused={isDropOffFocused}
                        resetFocus={resetFocus}
                        handleLocationSelect={handleLocationSelect}
                        fieldCompleted={fieldCompleted}
                        isSubmitted={isSubmitted}
                        handleSubmit={handleSubmit}
                        form={form}
                        handleFieldChange={handleFieldChange}
                        handleClearTextField={handleClearTextField}
                        robots={robots}
                        maps = {isPickUpFocused ? searchResults.pickUpResults : isDropOffFocused ? searchResults.dropOffResults : listOfLocations2}
                        handleRecipientLocationChange={handleRecipientLocationChange}
                    />
                )}
                {showRobotStatus && (
                    <RobotStatus showRobotStatus={showRobotStatus}  handleSelectedJob={handleSelectedJob2} robotInfo={selectedRobot3 ?? {}} handleCloseTab={handleCloseRobotStatus} handleBackButton={handleBackRobotStatus} />
                )}

                {showRobotList && (
                    <RobotList showRobotList={showRobotList} handleRobotList={handleRobotList} onRobotSelect={(robot: RobotInfo) => onRobotClick2(robot)} robots={robots} handleCloseRobotList={handleCloseRobotList}/>
                )}
                {showGeneralStatus && (
                    <GeneralStatus handleStatusBoardClose={handleShowGeneralStatus} />    
                )}
                <Stack
                    sx={{
                        position: "absolute",
                        top: "180px",
                        right: "30px",
                        display: "flex",
                        flexDirection: "row",
                        bgcolor:"white",
                        padding: "8px",
                        borderRadius: "4px",
                        px: 2,
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        
                    }}>
                        <Typography sx={{ 
                            fontSize:"14px", 
                            mr:3, 
                            color: showGeneralStatus ? "#378FFE" : "#9E9E9E",
                            // textShadow: `-1px -1px 0 #B0B0B0, 1px -1px 0 #B0B0B0, -1px 1px 0 #B0B0B0, 1px 1px 0 #B0B0B0`, 
                            '&:hover': { cursor:"pointer", color: "#378FFE" } }} 
                            onClick={handleShowGeneralStatus}>
                            Live Status
                        </Typography>
                        <Divider orientation="vertical" sx={{ height: "20px", color: "#9E9E9E", mr:3 }} />

                        <Typography sx={{ 
                            fontSize:"14px", 
                            mr:3, 
                            color: showRobotList ? "#378FFE" : "#9E9E9E",
                            // textShadow: `-1px -1px 0 #B0B0B0, 1px -1px 0 #B0B0B0, -1px 1px 0 #B0B0B0, 1px 1px 0 #B0B0B0`, 
                            '&:hover': { cursor:"pointer", color: "#378FFE" } }} 
                            onClick={handleRobotList}>
                            Robots
                        </Typography>
                        <Divider orientation="vertical" sx={{ height: "20px", color: "#9E9E9E", mr:3 }} />

                        <Typography sx={{ 
                            fontSize:"14px", 
                            // fontWeight:"600",    
                            color: isJobTab ? "#378FFE" : "#9E9E9E",
                            // textShadow: `-1p x -1px 0 #B0B0B0, 1px -1px 0 #B0B0B0, -1px 1px 0 #B0B0B0, 1px 1px 0 #B0B0B0`, 
                            '&:hover': { cursor:"pointer", color: "#378FFE" } }} 
                            onClick={handleJobTab}>
                            Today's Jobs
                        </Typography>
                </Stack>
                {isJobTab && ( <JobBoard handleJobBoardClose={handleJobBoardClose} handleSelectedJob={handleSelectedJob2} selectedJob={selectedJob2 } imageHeight={imageHeight} activeStep={activeStep} handleJobSearchField={handleJobSearchField} searchValue={searchValue} listOfJobs={todayJobs} handleBackButton={handleJobBackButton} /> )}
                
            </Stack>

            
            <Stack
                sx={{
                    position: "absolute",
                    bottom: "30px",
                    left: "40px",
                }}>
                {
                    !isOpen && (
                        <Button variant="contained" sx={{ width: "62px", height:"62px", bgcolor: "#2C8DFF", color: "white", fontWeight:"600", borderRadius:"31px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} onClick={handleIsOpen}>
                            <img src={parcel}style={{ width: "30px", height: "30px" }} />
                        </Button>
                    )
                }
            </Stack>
            <Stack
                sx={{
                    position: "absolute",
                    bottom: "30px",
                    right: "40px",
                }}>
                {
                    !triggerFireState && (
                        <Button variant="contained" sx={{ width: "62px", height:"62px", bgcolor: "#FF0B0B", color: "white", fontWeight:"600", borderRadius:"31px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" , '&:hover': { cursor:"pointer", bgcolor:"#bf0404" } }} onClick={() => dispatch(triggerFire())}>
                            {/* <img src={parcel}style={{ width: "30px", height: "30px" }} /> */}
                            <LocalFireDepartmentIcon sx={{ fontSize: "35px" }} />
                        </Button>
                    )
                }
            </Stack>
            <Stack
                direction={"column"}
                sx={{
                    position: "absolute",
                    bottom: "150px",
                    left: "40px",
                    width:"40px",
                    height:"40px",
                }}>
                    {/* <Button sx={{minWidth:"40px", minHeight:"40px", bgcolor: "rgba(187, 187, 187, 0.5)", color: "white", fontWeight:"600", borderRadius:"4px", fontSize:"16px", mb:0.2, '&:hover': { cursor:"pointer", bgcolor:"rgba(96, 96, 96, 0.62)" } }} onClick={() => {
                        dispatch(toggleLegend());
                        console.log("toggling legend, ", legendOpen);

                    }}>
                        <LegendToggleIcon />
                    </Button> */}
                    <Button sx={{minWidth:"40px", minHeight:"40px", bgcolor: "rgba(187, 187, 187, 0.5)", color: "white", fontWeight:"600", borderRadius:"4px", fontSize:"16px", mb:0.2, '&:hover': { cursor:"pointer", bgcolor:"rgba(96, 96, 96, 0.62)" } }} onClick={() => dispatch(zoomIn())}>
                        +
                    </Button>
                    <Button sx={{minWidth:"40px", minHeight:"40px", bgcolor: "rgba(187, 187, 187, 0.5)", color: "white", fontWeight:"600", borderRadius:"4px", fontSize:"16px", '&:hover': { cursor:"pointer", bgcolor:"rgba(96, 96, 96, 0.62)" } }} onClick={() => dispatch(zoomOut())}>
                        -
                    </Button>
                    
            </Stack>
            { isNotificationOn ? 
                <>
                    <Notification/>
                    <audio ref={audioRef} src={audioSrc} autoPlay />
                </>
                : null
            }
            <FireAlarmModal/>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <NotificationHistoryPanel open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} dummyNotificationHistory={dummyNotificationHistory} handleSelectJob={handleSelectedJob2} handleDeleteNotification={handleDeleteNotification} />
            {/* <NotificationPopUp open={notificationModalOpen} handleCloseNotificationModal={handleCloseNotificationModal} dummyNotificationHistory={dummyNotificationHistory} latestNotificationId={latestNotificationId} />
            {notificationModalOpen && <audio ref={audioRef} src={audioSrc}  autoPlay/>} */}

        </div>  
    );
}

export default Dashboard;

// ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Adjust the path as necessary

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const auth = useAuth(); // Using it this way ensures you get proper type checking

    if (!auth) {
        // Ideally handle this case, possibly showing an error or a different loading state
        return <div>Authentication context is unavailable</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>; // Placeholder for loading state
    }

    return auth.isLoggedIn ? <>{children}</> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;

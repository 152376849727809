import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Fuse from "fuse.js";
import { useWebSocket } from '../../components/useWebSocket';
import JobTable from './Components/JobTable';
import { Box, Typography, Button, Grid, TextField, InputAdornment, Popover, Alert, Chip , Dialog, DialogContentText, CircularProgress, DialogActions, DialogTitle, DialogContent} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs'; // Import dayjs and Dayjs type
import { JobDetails3, JobData2 } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { setTotalJobNumber, setTotalPageNumber, setCurrentPageNumber, setJobsPerPage  } from "../../store/page/jobPageSlice";
import csv from '../../assets/csv.png';
import axios from 'axios';

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface JobData {
    [order_number: string]: JobDetails2;
}

interface columnType {
    id: string;
    label: string;
    padding?: string;
}

interface Duration {
    start_time: number;
    end_time: number;
}

const JobHistory: React.FC = () => {
    // const [jobs, setJobs] = useState<JobData>({});
    const [jobs, setJobs] = useState<JobData2>({});

    const [filteredJobs, setFilteredJobs] = useState<JobData2>({});
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const profile = JSON.parse(localStorage.getItem('profile') || '{}');
    const jobOverviewUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${profile.uuid}/`;
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const pageSize = useSelector((state: any) => state.jobPage.jobsPerPage);
    const pageNumber = useSelector((state: any) => state.jobPage.currentPageNumber);
    const [reportId, setReportId] = useState<number>(0);
    const [duration, setDuration] = useState<Duration>({ start_time: 0, end_time: 0 });

    const getJobList = async (pageNumber: number, pageSize: number) => {
        console.log("duration", duration);
        console.log("getting job list");
        console.log("api url", `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/?page=${pageNumber}&page_size=${pageSize}&start_time=${duration.start_time == 0? "": duration.start_time}&end_time=${duration.end_time == 0? "": duration.end_time}`);
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/?page=${pageNumber}&page_size=${pageSize}&start_time=${duration.start_time == 0? "": duration.start_time}&end_time=${duration.end_time == 0? "": duration.end_time}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                dispatch(setTotalJobNumber(response.data.count));
                dispatch(setTotalPageNumber(response.data.num_pages));
                dispatch(setCurrentPageNumber(response.data.current_page));
                setJobs(response.data.data.reduce((acc: JobData2, job: JobDetails3) => {
                    acc[job.job_id] = job;
                    return acc;
                }, {}));
                // setListOfLocations(response.data);
                // setListOfLocations2(response.data.data);
                // dispatch(storeMapPoints(response.data.data));
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }

    const createReport = async () => {
        await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/generate_report/`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            data: duration.start_time && duration.end_time ? duration : null,
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                setReportId(response.data.report_id);
                setIsExporting(true);
                setTimeout(() => {
                    setIsExporting(false);
                    navigate(`/reports`);
                }, 5000);
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }
    // const handleMessage = useCallback((data: any) => {
    //     let job = data;
    //     setJobs(prevJobs => ({
    //         ...prevJobs,
    //         [job.order_number]: job
    //     }));
    // }, []);

    const fuseOptions = {
        keys: ["job_id"],
        includeScore: true
    };

    const [rangeCompleted, setRangeCompleted] = useState<Boolean>(false);
    const fuse = new Fuse(Object.values(jobs), fuseOptions);

    useEffect(() => {
        if (searchTerm) {
            const results = fuse.search(searchTerm).map(result => result.item);
            setFilteredJobs(Object.fromEntries(results.map(job => [job.job_id, job])));
        } else {
            setFilteredJobs(jobs);
        }
    }, [searchTerm, jobs]);

    useEffect(() => {
        getJobList( pageNumber, pageSize);
    }, []);

    const jobsColumn: columnType[] = [
        { id: 'order_number', label: 'Job No.', padding: "15px 20px" },
        { id: 'job_type', label: 'Job Type', padding: "15px 20px" },
        { id: 'robot_name', label: 'Robot Name', padding: "15px 20px" },
        // { id: 'robot_id', label: 'Mac Address', padding: "15px 20px" },
        // { id: "pick up_time", label: "Pick Up Time", padding: "15px 20px" },
        // {id: "drop off_time", label: "Drop Off Time", padding: "15px 20px"},
        { id: 'load_compartment', label: 'Compartment', padding: "15px 20px" },
        { id: 'sender_location', label: 'From' },
        { id: 'recipient_location', label: 'To' },
        { id: 'status', label: 'Status' },
        { id: 'duration', label: 'Duration' },
        { id: 'created_at', label: 'Created Time' },
        { id: 'timestamp', label: 'Completed Time' },
    ];

    // useWebSocket(jobOverviewUrl, handleMessage);

    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setRangeCompleted(false);
    };

    const handleClose = () => {
        if (endDate && endDate.isAfter(dayjs(), 'day') || startDate && startDate.isAfter(dayjs(), 'day')) {
            setError("End date cannot be after today.");
            return;
        }
        if (startDate && endDate && endDate.isBefore(startDate, 'day')) {
            setError("End date cannot be before start date.");
            return;
        }
        setError("");
        setAnchorEl(null);
        if (startDate && endDate) {
            setRangeCompleted(true);
        }
    };

    const handleClickAway = () => {
        setAnchorEl(null);
        setError("");
        if (startDate && endDate) {
            return; 
        }
        setStartDate(null);
        setEndDate(null);
        setRangeCompleted(false);
        setDuration((prev) => ({ ...prev, start_time: 0, end_time: 0 }));
    };

    const handleStartDateChange = (newStartDate: string | number | Dayjs | Date | null | undefined): void => {
        // if (!newStartDate) {
        //     setStartDate(null);
        //     return;
        // }
        // newStartDate = dayjs(newStartDate);
        // setStartDate(newStartDate);
        if (!newStartDate || !dayjs.isDayjs(newStartDate)) {
            setStartDate(null);
            setDuration((prev) => ({ ...prev, start_time: 0 }));
            return;
        }
    
        // Set the start date to the start of the day (00:00)
        const startOfDay = dayjs(newStartDate).startOf('day');
        setStartDate(startOfDay);
        setDuration((prev) => ({
            ...prev,
            start_time: startOfDay.unix(), // Unix timestamp for start time at 00:00
        }));

        console.log("newStartDate", newStartDate, newStartDate.unix());
        if (newStartDate && newStartDate.isAfter(dayjs(), 'day')) {
            setError("Start date cannot be after today.");
            return;
        }
        if (endDate && newStartDate && !newStartDate.isBefore(endDate, 'day')) {
            setEndDate(null); // Reset the end date if it's before the new start date
        }
    };

    const handleEndDateChange = (newEndDate: string | number | Dayjs | Date | null | undefined): void => {
        // if (!newEndDate) {
        //     setEndDate(null);
        //     return;
        // }
        // newEndDate = dayjs(newEndDate);
        if (!newEndDate || !dayjs.isDayjs(newEndDate)) {
            setEndDate(null);
            setDuration((prev) => ({ ...prev, end_time: 0 }));
            return;
        }
    
        // Use endOf('day') to set the end time to the end of the day (23:59:59)
        const endOfDay = dayjs(newEndDate).endOf('day');
        setEndDate(endOfDay);
    
        // Update the duration with the Unix timestamp for the end of the day
        setDuration((prev) => ({
            ...prev,
            end_time: endOfDay.unix(), // Unix timestamp for end time at 23:59:59
        }));
    
        console.log("newEndDate", endOfDay.format(), endOfDay.unix());
        if (newEndDate && newEndDate.isAfter(dayjs(), 'day')) {
            setError("End date cannot be after today.");
            return;
        }
        if (startDate && newEndDate && newEndDate.isBefore(startDate, 'day')) {
            setError("End date cannot be before start date.");
            return;
        }
        setEndDate(newEndDate);
        setError("");
    };

    const [isExporting, setIsExporting] = useState(false);

    const handleExport = () => {
        console.log ("Exporting data", duration);
        createReport();
    }   

    useEffect(() => {
        getJobList(pageNumber, pageSize);
    }, [rangeCompleted]);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <Box sx={{ maxWidth: "95%", m: "15px auto", height: "100%",}}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px auto" }}>
                    <TextField
                        id="search-store-name"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            width: "94%",
                            background: "white",
                            "& .MuiOutlinedInput-root": {
                                height: "40px",
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box>
                    <Button
                        aria-describedby={open ? 'simple-popover' : undefined}
                        variant="contained"
                        onClick={handleFilterClick}
                        sx={{ marginLeft: 2, background: "#fff", border: "1px solid #c0c0c0", boxShadow: "none", '&:hover': { background: "#f5f5f5" } }}   
                    >
                        <FilterAltIcon sx={{ color: "#c0c0c0" }} />
                    </Button>
                    </Box>
                    <Button
                        variant="outlined"
                        sx={{ marginLeft: 2 }}
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                    
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClickAway}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={
                            {
                                vertical: 'top',
                                horizontal: 'right',
                            }
                        }
                    >
                        <Box sx={{ p: 2, width: 400 }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                            <Typography sx={{ mb: 2 }}>Select Date Range:</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={5.5}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                    />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Typography sx={{ mt: 2 }}>-</Typography>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <DatePicker
                                        label="End Date"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        minDate={startDate || dayjs()}
                                        maxDate={dayjs()}
                                    />
                                </Grid>
                            </Grid>
                            <Button onClick={handleClose} sx={{ mt: 2, display: 'block', width: '100%' }}>
                                Apply
                            </Button>
                        </Box>
                    </Popover>
                </Box>
                {rangeCompleted && (
                    <Chip label={`${startDate ? startDate.format('DD/MM/YYYY') : 'Start Date'} - ${endDate ? endDate.format('DD/MM/YYYY') : 'End Date'}`} sx={{ mt: 1, backgroundColor:"#F1F7FD",color:"#378FFE", border: "1px solid #CEDBFF"}} variant="outlined" onDelete={() => { setStartDate(null); setEndDate(null);  setRangeCompleted(false)}} />
                )}
                <JobTable columns={jobsColumn} items={filteredJobs} getJobList= {getJobList} />
                <Dialog open={isExporting} onClose={() => setIsExporting(false) } >
                    <DialogTitle>Report Generation</DialogTitle>
                    <DialogContent>
                        <Box sx={{display:"flex", flexDirection:"column", alignText: "justify"}} mb={2}>
                            <DialogContentText>
                                Your file is being generated. Redirecting to the download page in a few seconds...
                            </DialogContentText>
                            <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="flex-start" mt={4} sx={{ width: "100%", mb:2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start" }}>
                                    <img src={csv} alt="csv" style={{ height: "50px" }} />
                                    <Typography component="div" sx={{ ml: 3, fontSize: "16px", mt: 1.5 }}>
                                        Report Reference ID: {reportId}
                                    </Typography>
                                </Box>
                                <CircularProgress sx={{ ml: 2 }} />
                            </Box>
                        </Box>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={() => navigate} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </Box>
        </LocalizationProvider>
    );
};

export default JobHistory;

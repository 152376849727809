import React, { useContext , useState, useEffect, useCallback } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Grid, TextField, InputAdornment, Popover, Alert, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RobotTable from './components/RobotTable';
import Fuse from 'fuse.js';
import { useSelector, useDispatch } from 'react-redux';

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [order_number: string]: JobDetails2;
}
interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}
interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
}

interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation_name: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface RobotColumn {
    id: string;
    label: string;
    padding?: string;
}

const Robots = () => {
    const profile = JSON.parse(localStorage.getItem('profile') || '{}');
    const accessToken = localStorage.getItem('accessToken');
    const [robots, setRobots] = useState<RobotsData>({});
    const [organisation, setOrganisation] = useState<string>("");
    const navigate = useNavigate();
    const [filteredRobots, setFilteredRobots] = useState<RobotsData>({});
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [fuse, setFuse] = useState<Fuse<RobotInfo> | null>(null);
    const dispatch = useDispatch();
    const robots2 = useSelector((state: any) => state.robot.robots);
    const queryRobots = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot/?robot_organisation__uuid=${profile.organisation}`, {
                headers: { "Authorization": `Bearer ${accessToken}` },
            });
            const robotsData = await Promise.all(data.map(async (robot: GeneralRobotInfo) => {
                console.log("Robot:", robot);
                const organisationName = await getOrganisation(robot.organisation);
                return {
                    ...robot,
                    organisation_name: organisationName,
                };
            }));
            console.log("Robots data:", robotsData);    
            const robotsMap = robotsData.reduce((acc, robot) => ({
                ...acc,
                [robot.robot_mac]: {
                    generalInfo: {
                        robot_name: robot.robot_name,
                        robot_mac: robot.robot_mac,
                        robot_serial_number: robot.robot_serial_number,
                        robot_type: robot.robot_type,
                        organisation: robot.organisation_name,
                    },
                },
            }), {});
            console.log("Robots map:", robotsMap);
            setRobots(robotsMap);
            setFuse(new Fuse(robotsData, {
                keys: ["robot_name", "robot_mac", "robot_serial_number", "robot_type", "organisation_name"],
                includeScore: true
            }));
        } catch (error) {
            console.error("Failed to fetch robots:", error);
        }
    };

    const getOrganisation = async (organisationId: string) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/auth/organisation/`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                },
            });
            // search for organisation
            const organisation = data.find((org: any) => org.uuid === organisationId);
            console.log("Organisation:", organisation);
            return organisation.name;
            // setOrganisation(organisation.name);
        } catch (error) {
            console.error("Failed to fetch organisation:", error);
            return null;
        }
    }

    useEffect(() => {
        // getOrganisation(profile.organisation);
        queryRobots();
    }, []);

    useEffect(() => {
        console.log("Robots:", robots2);
    }, [robots2]);

    const robotColumns: RobotColumn[] = [
        { id: "product_name", label: "Product Name" },
        { id: "robot_name", label: "Robot Name" },
        { id: "robot_serial_number", label: "Serial No." },
        { id: "robot_mac", label: "Mac Address" },
        { id: "organisation", label: "Organisation" },

        // hardcoded for now
        { id: "site", label: "Site" },
        { id: "site_address", label: "Site Address" },
        { id:"customer_name", label: "Customer Name" },
        { id: "customer_contact", label: "Customer Contact" },
        { id: "active", label: "Activation State" },
        { id: "purchase_type", label: "Purchase Type" },
        { id: "software_version", label: "Software Version" },
        { id: "remarks", label: "Remarks" },
    ];

    // const fuseOptions = {
    //     keys: ["robot_name", "robot_mac", "robot_serial_number", "robot_type", "organisation"],
    //     includeScore: true
    // };

    // const fuse = new Fuse(Object.values(robots), fuseOptions);
    
    useEffect(() => {
        if (searchTerm && fuse) {
            const results = fuse.search(searchTerm).map(({ item }) => item);
            console.log("Results:", results);
            const resultsMap = results.reduce((acc, robot) => ({
                ...acc,
                [robot.generalInfo?.robot_mac]: {
                    generalInfo: robot
                }
            }), {});
            setFilteredRobots(resultsMap);
            
            console.log("Filtered robots:", results);
        } else {
            setFilteredRobots(robots2);
        }
    }, [searchTerm, robots2]);

    useEffect(() => {
        console.log("Filtered robots:", filteredRobots);
    }, [filteredRobots]);
    
    return ( 
        <Box sx={{ maxWidth: "95%", m: "15px auto" }}>
            <TextField
                id="search-store-name"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                    width: "100%",
                    background: "white",
                    "& .MuiOutlinedInput-root": {
                        height: "40px",
                    },
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <RobotTable
                items={filteredRobots}
                columns={robotColumns}
            />
        </Box>
     );
}
 
export default Robots;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LegendState {
  isLegendOn: boolean;
}

const initialState: LegendState = {
  isLegendOn: false, 
};

export const toggleLegendSlice = createSlice({
  name: 'toggleLegend',
  initialState,
  reducers: {
    toggleLegend: (state) => {
      state.isLegendOn = !state.isLegendOn; 
    },
    setLegendOn: (state) => {
      state.isLegendOn = true; 
    },
    setLegendOff: (state) => {
        state.isLegendOn = false; 
    },
  },
});

export const { toggleLegend, setLegendOn, setLegendOff } = toggleLegendSlice.actions;

export default toggleLegendSlice.reducer;

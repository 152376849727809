import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RobotInfo, RobotsData, RobotStatus2, JobData } from '../types';

interface RobotState {
    selectedRobot: RobotInfo;
    isSelectedRobot: boolean;
    robots: RobotsData;
}


const initialState: RobotState = {
    selectedRobot:   {
        robotStatus: {
            battery: 0,
            can_assign_job: true,
            is_online: true,
            map: "",
            organisation: "",
            pose: {
                latitude: 1.2973538783600418,
                longitude: 103.78681142555857,
                orientation: 0
            },
            robot_id: "",
            robot_state: "",
            robot_type: "",
            timestamp: 0,
            robot_name: "",
            robot_serial_number: ""
        },
        jobs: {},
        generalInfo: {
            robot_name: "",
            robot_mac: "",
            robot_serial_number: "",
            robot_type: "",
            organisation: ""
        }
    },
    isSelectedRobot: false,
    robots: {}
};
    

export const robotSlice = createSlice({
  name: 'robot',
  initialState,
  reducers: {
    setSelectedRobot: (state, action: PayloadAction<RobotInfo>) => {
      state.selectedRobot = action.payload;
    },
    setIsSelectedRobot: (state, action: PayloadAction<boolean>) => {
        state.isSelectedRobot = action.payload;
    },
    addRobot: (state, action: PayloadAction<RobotsData>) => {
        state.robots = {...state.robots, ...action.payload};
    },
    updateRobot: (state, action: PayloadAction<RobotsData>) => {
        return {...state, ...action.payload};
    },
    updateRobotStatus: (state, action: PayloadAction<RobotStatus2>) => {
        const robotId = action.payload.robot_id;
        if (state.robots[robotId]) {
            state.robots[robotId].robotStatus = action.payload;
        }
    },
    updateJobData: (state, action: PayloadAction<JobData>) => {
        const job = action.payload[Object.keys(action.payload)[0]];
        const robotId = job.robot_id;

        // Check if robot exists in state
        if (state.robots[robotId]) {
            // If the robot exists, update its jobs
            state.robots[robotId].jobs = {
                ...state.robots[robotId].jobs,
                [job.order_number]: job
            };
        } else {
            // If robot does not exist, initialize it in the state
            state.robots[robotId] = {
                robotStatus: {
                    battery: 0,
                    can_assign_job: true,
                    is_online: true,
                    map: "",
                    organisation: "",
                    pose: {
                        latitude: 0,
                        longitude: 0,
                        orientation: 0
                    },
                    robot_id: robotId,
                    robot_state: "",
                    robot_type: "",
                    timestamp: Date.now(),
                    robot_name: "",
                    robot_serial_number: ""
                },
                jobs: {
                    [job.order_number]: job
                },
                generalInfo: {
                    robot_name: "",
                    robot_mac: robotId,
                    robot_serial_number: "",
                    robot_type: "",
                    organisation: ""
                }
            };
        }
    },
},
});
//     updateRobotStatus: (state, action: PayloadAction<RobotStatus2>) => {
//         state.robots[action.payload.robot_id].robotStatus = action.payload;
//     },
//     updateJobData: (state, action: PayloadAction<JobData>) => {
//         state.robots[action.payload[Object.keys(action.payload)[0]].robot_id].jobs = action.payload;
//     },
//   },
// });

export const { setSelectedRobot, setIsSelectedRobot, addRobot, updateRobot, updateRobotStatus, updateJobData } = robotSlice.actions;

export default robotSlice.reducer;

import React from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import { Box, Typography } from '@mui/material';
import { GIS } from '../../../components/RobotMap/RobotMap';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
interface LocationMarkerProps extends GIS {
    name: string;
    x: number;
    y: number;
    color: string;
    handleLocationSelect?: (location: string) => void;
    selected?: boolean;
}
const LocationMarker: React.FC<LocationMarkerProps> = ({ name, x, y, color, handleLocationSelect, GIS, selected }) => {
    const markerStyle: React.CSSProperties = {
      position: 'absolute',
      top: `${y}%`,
      left: `${x}%`,
      transform: 'translate(-50%, -50%)', // Center the icon and text
      cursor: 'pointer'
    };
  
    return (
      <>
      <Box onClick={() => handleLocationSelect && handleLocationSelect(name)}>
        <RadioButtonCheckedIcon style={{ color: color, fontSize: '40px' }} />
        <Typography variant="subtitle2" sx={{ color: color, fontSize: '14px', textAlign:"center"}}>{name}</Typography>
      </Box>
      {/* <PlaceIcon style={{ color: color, fontSize: '100px', position: 'absolute', top:-60, left:-25 }} /> */}
      {selected && <PlaceIcon style={{ color: color, fontSize: '100px', position: 'absolute', top:-65, left:-30 }} />}
      </>
    );
};

export default LocationMarker;
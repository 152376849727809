import { Box, Button } from "@mui/material";
import mapboxgl, { LngLatLike, Map, Marker } from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import RobotIcon from "../icons/RobotIcon";
import { createRoot } from "react-dom/client";
import {
  loadOverlay,
  useRenderGISElementOnMap,
  useRenderLinesOnMap,
  useRenderRobotOnMap,
} from "./helpers/map";
import OverlaySetting from "./models/OverlaySetting";
import "./RobotMap.css";
import { mapboxToken } from "./constants";
import { Robot } from "./models/Robot";
import { useSelector, useDispatch } from "react-redux";
import { setZoomLevel } from "../../store/map/mapSlice";  // import the action creator

mapboxgl.accessToken = mapboxToken;

export interface RobotMapProps {
  startLocation: LngLatLike;
  zoomLevel: number;
  mapOverlay: OverlaySetting;
  robots: Robot[];
  children?: React.ReactElement<GIS>[];
  line?: [number, number][];
  onRobotClick?: (robot: Robot) => void;
  renderLocationMarkers?: () => JSX.Element[];
}


export interface GIS {
  GIS: LngLatLike;
}

const RobotMap: React.FC<RobotMapProps> = ({
  startLocation,
  zoomLevel,
  mapOverlay,
  robots,
  children = [],
  line = [],
  onRobotClick,
  renderLocationMarkers
}: RobotMapProps) => {
  const mapContainer = useRef<HTMLElement | string>("");
  const map = useRef<Map | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (map.current) return; // initialize map only once
    console.log("reinitializing map");
    map.current = new Map({
      container: mapContainer.current,
      style: "mapbox://styles/hanscau/clxan4269025p01qxaxqc0ekr",
      center: startLocation,
      zoom: zoomLevel,
    });

    map.current.on("load", () => {
      const mapboxMap = map.current as Map;
      loadOverlay(map, mapOverlay, "overlay");
      // const mapOverlaySetting2 = {
      //   url: "assets/autove-floorplan.png",
      //   topLeftLng: 103.78595945422515,
      //   topLeftLat: 1.2976129210499472,
      //   widthInGIS: 0.0011181175118634928,
      //   heightInGIS: 0.0005332418731876894,
      // };
      // loadOverlay(map, mapOverlaySetting2, "overlay2");

      mapboxMap.on("click", (e) => {
        var coordinate = e.lngLat;
        console.log(coordinate);
      });
    });
    // map.current.addControl(new mapboxgl.NavigationControl());
  });
  
  // call useRenderGISElementOnMap on mount

  
  useRenderGISElementOnMap(map, children);
  useRenderRobotOnMap(map, robots, onRobotClick, );
  useRenderLinesOnMap(map, line);
  // renderLocationMarkers && renderLocationMarkers();
  

  console.log("rendering map", robots);
  const updateZoomLevel = useSelector((state: any) => state.map.zoomLevel);
  const selectedRobot = useSelector((state: any) => state.robot.selectedRobot);
  const isSelectedRobot = useSelector((state: any) => state.robot.isSelectedRobot);
  const isJobSelected = useSelector((state: any) => state.job.isJobSelected);
  const selectedJob = useSelector((state: any) => state.job.selectedJob);
  const listOfPoints = useSelector((state: any) => state.map.mapDetails);
  const senderLocation = useSelector((state: any) => state.map.senderLocation);
  const recipientLocation = useSelector((state: any) => state.map.recipientLocation);
  // focus on location 
  useEffect(() => {
    if (senderLocation!="" && Array.isArray(listOfPoints)) {
      const sender = listOfPoints.find((point: any) => point.location_name === senderLocation);
      console.log("flying to sender", sender.pose);
      if (map.current) {
        map.current.flyTo({ center: [sender.pose.longitude, sender.pose.latitude], zoom: 20 });
      }
    }
  }, [senderLocation]);

  useEffect(() => {
    if (recipientLocation!="" && Array.isArray(listOfPoints)) {
      const recipient = listOfPoints.find((point: any) => point.location_name === recipientLocation);
      console.log("flying to recipient", recipient.pose);
      if (map.current) {
        map.current.flyTo({ center: [recipient.pose.longitude, recipient.pose.latitude], zoom: 20 });
      }
    }
  }, [recipientLocation]);

  useEffect(() => {
    console.log("flying to selected robot", selectedRobot.robotStatus?.pose);
    if (isSelectedRobot ) {
      if (map.current) {
          map.current.flyTo({ center: [selectedRobot.robotStatus?.pose.longitude, selectedRobot.robotStatus?.pose.latitude], zoom: 20 });
      }
    }
    else{
      if (map.current) {
          map.current.flyTo({ center: startLocation, zoom: 18 });
      }
    }
  }, [isSelectedRobot]);

  useEffect(() => {
    if (isJobSelected && Array.isArray(listOfPoints)) {
      // get sender location
      console.log("flying to selected job", selectedJob);
      var senderLocation = listOfPoints.find((point: any) => point.location_name === selectedJob.sender_location);
      var recipientLocation = listOfPoints.find((point: any) => point.location_name === selectedJob.recipient_location);
      console.log("flying to selected job 1", senderLocation, recipientLocation);
      if (senderLocation === undefined || recipientLocation === undefined) {
        senderLocation = {
          pose: {
            longitude: 103.8585709834108,
            latitude: 1.282174630782123
          }
        }
        recipientLocation = {
          pose: {
            longitude: 103.8585709834108,
            latitude: 1.282174630782123
          }
        }
      }
      // find midpoint
      const midpoint = {
        longitude: (senderLocation.pose.longitude + recipientLocation.pose.longitude) / 2,
        latitude: (senderLocation.pose.latitude + recipientLocation.pose.latitude) / 2
      }
      console.log("flying to selected job 2", midpoint);
      if (map.current) {
          map.current.flyTo({ center: [midpoint.longitude, midpoint.latitude], zoom: 19 });
      }
    }
    else{
      if (map.current) {
          console.log("flying to selected job back" );
          if (isSelectedRobot) {
            map.current.flyTo({ center: [selectedRobot.robotStatus?.pose.longitude, selectedRobot.robotStatus?.pose.latitude], zoom: 20 });
          }
          else{
            map.current.flyTo({ center: startLocation, zoom: 18 });
          }
      }
    }
  }
  , [selectedJob, isJobSelected]);

  useEffect(() => {
    console.log("updateZoomLevel", updateZoomLevel);
    if (map.current) {
      if (map.current.isStyleLoaded()) {
        map.current.flyTo({ zoom: updateZoomLevel });
      } 
    }
  }, [updateZoomLevel ]);

  useEffect(() => {
    if (map.current) {
      map.current.on('zoomend', () => {
        const newZoomLevel = map.current!.getZoom();
        dispatch(setZoomLevel(newZoomLevel));
        console.log('Current zoom level:', newZoomLevel);
      });
    }
  }, [map.current]);
  

  return (
    <Box
      ref={mapContainer}
      sx={{
        width: "100%",
        height: "100%",
        // zIndex: 0,
      }}
    >
    </Box>
  );
};

export default RobotMap;

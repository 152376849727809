import { configureStore } from '@reduxjs/toolkit';
import deliveryDashboardSlice from './deliveryDashboardSlice';
import mapSlice from './map/mapSlice';
import toggleLegendSlice from './map/toggleLegendSlice';
import fireTriggerSlice from './fire/fireTriggerSlice';
import notificationSlice from './notification/notificationSlice';
import robotSlice from './robot/robotSlice';
import jobSlice from './job/jobSlice';
import formSlice from './job/formSlice';
import searchSlice from './job/searchSlice';
import jobPageSlice from './page/jobPageSlice';

export const store = configureStore({
    reducer: {
        deliveryDashboard: deliveryDashboardSlice,
        map: mapSlice,
        toggleLegend: toggleLegendSlice,
        fire: fireTriggerSlice,
        notification: notificationSlice,
        robot: robotSlice,
        job: jobSlice,
        form: formSlice,
        search: searchSlice,
        jobPage: jobPageSlice,
    },
});
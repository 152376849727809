import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination
} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import { setTotalReportNumber, setCurrentReportPageNumber, setTotalRobotJobsPageNumber, setTotalReportPerPage  } from "../../../store/page/jobPageSlice";
import {useState, useEffect} from 'react';
import axios from 'axios';

interface ReportTableProps {
  items: ReportState[];
  columns: columnType[];
  getReportData: (pageNumber: number, rowsPerPage: number) => void;
}

interface columnType {
  id: string;
  label: string;
  padding?: string;
}

interface ReportState {
  id: number;
  requester: string;
  generating_time: number;
  completed_time: number;
  start_time: number;
  end_time: number;
  status: string;
  report_csv: string;
}

const ReportTable: React.FC<ReportTableProps> = ({ items, columns, getReportData }) => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const totalReportNumber = useSelector((state: any) => state.jobPage.totalReportNumber);
    

    const handleChangePage = (event: null | React.MouseEvent<HTMLButtonElement>, newPage: number) => {
        setPage(newPage);
        getReportData(newPage + 1, rowsPerPage); // Backend might count pages starting from 1
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getReportData(1, parseInt(event.target.value, 10));
      };
    
      useEffect(() => {
        getReportData(page + 1, rowsPerPage);
      }, [page, rowsPerPage]);
      
    const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
    const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const time = date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: true });

    return (
      <>
        <Typography variant="body2" component="span" sx={{fontSize:"13px"}}>{time}</Typography>
        <br/>
        <Typography variant="body2" component="span" sx={{fontSize:"13px"}}>{date.toLocaleDateString('en-GB', dayOptions)}, {date.toLocaleDateString('en-GB', dateOptions)}</Typography>
      </>
    );
  };


  const renderCellContent = (columnId: string, item: ReportState) => {
    switch (columnId) {
      case 'id':
        return item.id;
      case 'requester':
        return item.requester;
      case 'generating_time':
        return formatDate(item.generating_time);
      case 'start_time':
        return item.start_time === -1 ? "All" : formatDate(item.start_time);
      case 'end_time':
        return item.end_time === -1 ? "All" : formatDate(item.end_time);
      case 'status':
        return item.status === "generating" && item.report_csv === null ? "Failed" : item.status.charAt(0).toUpperCase() + item.status.slice(1);
      case 'report_csv':
        return (
          <Button
            variant="contained"
            color="primary"
            href={item.report_csv}
            target="_blank"
            disabled={!item.report_csv}
          >
            Download
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <TableContainer component={Paper} sx={{overflow:"auto ", maxHeight:"680px"}}>
      <Table sx={{overflow:"auto"}} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align="center" sx={{ padding: column.padding }}>
                <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center',  }}>
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              {columns.map((column) => (
                <TableCell key={column.id + item.id} align="center" sx={{ padding: "10px", fontSize:"13px" }}>
                  {renderCellContent(column.id, item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        
      </Table>
      <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={totalReportNumber}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ flexShrink: 0, boxShadow:"none" }} // Prevent pagination from shrinking
            />
    </TableContainer>
  );
}

export default ReportTable;
    
import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Avatar, IconButton, Box } from '@mui/material';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import MonitorIcon from '@mui/icons-material/Monitor';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import autove from '../../assets/autove.jpeg';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setFalse } from '../../store/deliveryDashboardSlice';
import AssessmentIcon from '@mui/icons-material/Assessment';
interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = [{
    name: 'Dashboard',
    icon: <MonitorIcon />,
    click: () => {
      navigate('/');
      toggleSidebar();
      dispatch(setFalse());
    }
  }, {
    name: 'Jobs',
    icon: <WorkHistoryIcon />,
    click: () => {
      navigate('/jobs');
      toggleSidebar();
      dispatch(setFalse());
    }
  }, 
  {
    name: 'Reports History',
    icon: <AssessmentIcon />,
    click: () => {
      navigate('/jobs');
      toggleSidebar();
      dispatch(setFalse());
    }
  },{
    name: 'Robots',
    icon: <SmartToyIcon />,
    click: () => {
      // navigate('/robots');
      toggleSidebar();
      dispatch(setFalse());
    }
  }];
  

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggleSidebar}
      sx={{
        backgroundColor: '#fff',
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Toolbar >
      <IconButton
          onClick={toggleSidebar}
          sx={{ marginLeft: 'auto' }} 
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 50px 0px' }}>
        <img src={autove} alt="logo" style={{ width: 70, height: 70, borderRadius:"10px" }} />
      </div>
      <List>
        {items.map((item, index) => (
          <ListItem button key={index} onClick={item.click}>
            <ListItemIcon sx={{ml:2}}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;

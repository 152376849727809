import { useEffect, useRef, useState } from 'react';

// Custom hook for handling WebSocket connections for receiving messages
export function useWebSocket(url: string, onMessage: (data: any) => void) {
  const websocket = useRef<WebSocket | null>(null);

  useEffect(() => {
    // Establish the WebSocket connection
    websocket.current = new WebSocket(url);
    console.log('WebSocket connected to', url);

    websocket.current.onmessage = event => {
      onMessage(JSON.parse(event.data));
    };

    websocket.current.onclose = () => console.log('WebSocket disconnected from', url);
    websocket.current.onerror = error => console.error('WebSocket error:', error);

    // Cleanup function to close WebSocket connection when component unmounts
    return () => websocket.current?.close();
  }, [url, onMessage]);

  // No need to return sendMessage as this hook is now tailored for receiving messages only
}

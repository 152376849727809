import React from 'react';
import { Box, Grid, Card, Typography, Divider } from '@mui/material';
import robots from '../../../assets/robots.png';
import idle from '../../../assets/idle.png';
import busy from '../../../assets/busy.png';
import offline from '../../../assets/offline.png';

interface AggregateCardProps {
  title: string;
  data: string;
  clicked: boolean;
}

const AggregateCard: React.FC<AggregateCardProps> = ({ title, data, clicked }) => {

  return (
    <Grid item xs={12} md={3} sx={{mt:-4}}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          bgcolor: clicked ? "rgba(24, 26, 51, 0.8)" : "white", 
          cursor: "pointer", 
          width: "100%",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: 2.5,
            mr:2, 
            height: "60px",
          }}
        >
          <Typography variant="h6" sx={{ color: "#9E9E9E", mt:-2 }}>
            {title}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={
                title === "Total Robots"
                  ? robots
                  : title === "Idle"
                  ? idle
                  : title === "Busy"
                  ? busy
                  : offline
              }
              alt="icon"
              style={{ height: 50, width: 50 }}
            />
            <Typography variant="h4" sx={{ color: "#000", marginLeft: "30px", mt: 1 }} >
                {data}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default AggregateCard;

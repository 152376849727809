import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobDetails2, JobData } from '../types';

interface RobotState {
    searchQuery: string;
}


const initialState: RobotState = {
    searchQuery: ''
};
    

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
        state.searchQuery = action.payload;
    },
  },
});

export const { setSearchQuery } = searchSlice.actions;

export default searchSlice.reducer;

import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import autoveLogo from '../../../assets/autoveLogo.png';
import robotDisplay from '../../../assets/robotDisplay.png';
import hand from '../../../assets/hand.png';
import robotmanager from '../../../assets/robotmanager.png';
import createdelivery from '../../../assets/createdelivery.png';
import display from '../../../assets/display.png';

const AutoveHalf = () => {
    return ( 
        <Box sx={{ flex: 1.5, display: 'flex', flexDirection: 'column', backgroundColor: "#white", borderRadius:"20px 0px 0px 20px" }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt:4, ml:4 }}>
            <Typography variant="h4" sx={{ ml: 3 , fontFamily:"Poppins", fontWeight:"500", color:"#757575"}}>
              RoboHub
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 5 }}>
            <img src={display} style={{width:"100%"}}/>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 4 }}>
              <Stack sx={{ position: 'relative', top : '0px', left: '0px', right: '0px', bottom: '0px', margin: 'auto' }}>
                <img src={robotmanager} alt="Vehicle" style={{ width: '350px', height: 'auto', margin:"auto" }} />
                <Stack sx={{ position: 'absolute', bottom:"-40px", left: "-140px" }}>
                  <img src={hand} alt="Vehicle" style={{ width: '200px', height: 'auto', margin:"auto" }} />
                </Stack>
                <Stack sx={{ position: 'absolute', top:"40px", right: "-150px" }}>
                  <img src={createdelivery} alt="Vehicle" style={{ width: '200px', height: 'auto', margin:"auto" }} />
                </Stack>
                <Stack sx={{ position: 'absolute', bottom:"-60px", left: "20px"}}>
                  <img src={robotDisplay} alt="Vehicle" style={{ width: '350px', height: 'auto', margin:"auto" }} />
                </Stack>
              </Stack>
            </Box> */}
            
            <Typography variant="h5" sx={{  mb:-3, fontWeight: 500, textAlign:"center", mt: 5 }}>
              Control Robots at Your FingerTips
            </Typography>
            <Typography sx={{ textAlign:"center", margin: "40px 10px", fontSize:"15px"  }}>
                Automate robot tasks by integrating them into your business processes and workflows, enhancing operational efficienc and scalability with minimal human interventions.
            </Typography>
          </Box>
        </Box>
     );
}
 
export default AutoveHalf;
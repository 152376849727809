// import React, { useState } from 'react';
// import TopBar from './Topbar';
// import Sidebar from './Sidebar';

// interface MainLayoutProps {
//   children: React.ReactNode;
//   handleNotificationCount: (count: number) => void;
//   notificationCount: number;
// }

// const MainLayout: React.FC<MainLayoutProps> = ({ children, handleNotificationCount, notificationCount }) => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <>
//       <TopBar isOpen={isSidebarOpen} handleIsOpen={toggleSidebar} handleNotificationDisplay={()=>{}} notificationCount={notificationCount} toggleSidebar={toggleSidebar} />
//       <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
//       <div style={{ marginLeft: isSidebarOpen ? 240 : 0 }}>
//         {children}
//       </div>
//     </>
//   );
// }

// export default MainLayout;

// MainLayout.js
import React, { useState, ReactNode } from 'react';
import TopBar from './Topbar';
import Sidebar from './Sidebar'; // Adjust the import path as necessary

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <div>
      <TopBar toggleSidebar={toggleSidebar} handleNotificationDisplay={() => {}} notificationCount={0} handleIsOpen={toggleSidebar} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <main>{children}</main>
    </div>
  );
};

export default MainLayout;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobDetails2, JobData } from '../types';

interface RobotState {
    selectedJob: JobDetails2;
    isJobSelected: boolean;
    jobs: JobData;
}


const initialState: RobotState = {
    selectedJob: {
        compartment_pin: "",
        id: 0,
        job_type: "",
        job_id: 0,
        organisation_id: "",
        load_compartment: 0,
        recipient_id: "",
        recipient_location: "",
        recipient_name: "",
        robot_id: "",
        sender_id: "",
        sender_location: "",
        sender_name: "",
        timestamp: 0,
        order_number: "",
        job_status: ""
    },
    isJobSelected: false,
    jobs: {}
    
};
    

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    setSelectedJob: (state, action: PayloadAction<JobDetails2>) => {
        state.selectedJob = action.payload;
    },
    setIsJobSelected: (state, action: PayloadAction<boolean>) => {
        state.isJobSelected = action.payload;
    },
    updateJob: (state, action: PayloadAction<{ [order_number: string]: JobDetails2 }>) => {
        // Update or add new job
        const newJob = action.payload;
        const orderNumber = Object.keys(newJob)[0];
        state.jobs[orderNumber] = newJob[orderNumber];
    }
  },
});

export const { setIsJobSelected, setSelectedJob, updateJob } = jobSlice.actions;

export default jobSlice.reducer;

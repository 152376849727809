import { Typography, duration } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { JobEnums } from "../../../utils/enums/JobEnums";

import {useState, useEffect} from 'react';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import { getStatusColor } from "../../../utils/ColourStatus";
import { JobData2, JobDetails3 } from "../../../store/types";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentPageNumber, setJobsPerPage } from "../../../store/page/jobPageSlice";

interface JobTableProps {
    actions?: string[];
    type?: string;
    columns: Column[];
    items: JobData2;
    getJobList: (pageNumber: number, pageSize: number) => void;
}

interface Column {
    id: string;
    label: string;
    padding?: string;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface JobData {
    [order_number: string]: JobDetails2;
}

interface ProcessedJobDetails {
    id: string;
    order_number: string;
    job_type: string;
    robot_name: string;
    robot_id: string;
    load_compartment: string;
    sender_location: string;
    recipient_location: string;
    status: string;
    created_at: number;
    timestamp: number;
}

const JobTable: React.FC<JobTableProps> = ({ actions, type, columns, items, getJobList}) => {

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' }; // E.g., "Tue"
        const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' }; // E.g., "01 Jan 2022"
    
        const day = date.toLocaleDateString('en-GB', dayOptions);
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        const time = date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: true }); // E.g., "11:59 PM"
    
        // return `${time}, ${day}, ${formattedDate}`;
        return (
            <>
                <Typography variant="body2" component="span" sx={{fontSize:"12px"}}>{time}</Typography>
                <br/>
                <Typography variant="body2" component="span" sx={{fontSize:"12px"}}>{day}, {formattedDate}</Typography>
            </>
        );
    };
    

    // const actions = ["Edit"];
    const token = localStorage.getItem('token');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    
    const defaultHeaderStyle = {
        fontSize: "14px",
        color: "grey",
        padding: "15px 50px", 
        fontWeight: "bold",
        backgroundColor: "#F5F9FF",
        whiteSpace: "nowrap"
    };

    // Render function
    const renderHeader = (column: Column) => {
        return {
            ...defaultHeaderStyle,
            padding: column.padding ? column.padding : defaultHeaderStyle.padding
        };
    };

    const content = {
        fontSize:"12px",
        color:"black",
        padding:"15px",
        // backgroundColor:"white"
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch = useDispatch();
    const totalPageNumber = useSelector((state: any) => state.jobPage.totalPageNumber);
    const currentPageNumber = useSelector((state: any) => state.jobPage.currentPageNumber);
    const totalJobs = useSelector((state: any) => state.jobPage.totalJobNumber);
    const jobsPerPage = useSelector((state: any) => state.jobPage.jobsPerPage);

    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    //     dispatch(setCurrentPageNumber(newPage + 1)); // if your API expects page 1 as the first page
    //     getJobList(newPage + 1, jobsPerPage); // Adjust API call accordingly
    // };

    // const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0); 
    //     dispatch(setCurrentPageNumber(0));
    //     dispatch(setJobsPerPage(parseInt(event.target.value, 10)));
    //     getJobList(0, parseInt(event.target.value, 10));
    // };

    const handleChangePage = (event: null | React.MouseEvent<HTMLButtonElement>, newPage: number) => {
        setPage(newPage);
        getJobList(newPage + 1, rowsPerPage); // Backend might count pages starting from 1
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getJobList(1, parseInt(event.target.value, 10));
      };
    
      useEffect(() => {
        getJobList(page + 1, rowsPerPage);
      }, [page, rowsPerPage]);

    const temp = Object.values(items).map((job: JobDetails3) => {
        // console.log("job: ", job);
        return {
            id: job.job_id,
            order_number: job.job_id,
            job_type: job.job_type,
            // robot_name: "Robot 1",
            robot_name: job.robot_name,
            robot_id: job.robot_id,
            load_compartment: job.compartment,
            sender_location: job.from_location,
            recipient_location: job.to_location,
            duration: job.duration,
            status: `${job.status}`,
            created_at: job.created_time,
            timestamp: job.completed_time
        };
    });
    // make it descending
    // const rowsToShow = temp && Array.isArray(temp) ? temp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reverse() : [];
    // const rowsToShow = temp && Array.isArray(temp) ? temp.reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];

    useEffect(() => {
        console.log("rowsToShow: ", temp);
    }, [temp]);
    

    const itemCount = temp && Array.isArray(temp) ? temp.length : 0;

    // const getStatusColor = (status: string) => {
    //     const normalizedStatus = status.toLowerCase();
    //     switch (normalizedStatus) {
    //         case JobEnums.ITEM_DELIVERED:
    //             return '#00C48C';
    //         case JobEnums.CANCELLED:
    //         case JobEnums.DELIVERY_FAILED:
    //         case JobEnums.PICKED_UP_FAILED:
    //         case JobEnums.RETURN_FAILED:
    //         case JobEnums.RETURN_TO_SENDER:
    //             return '#FF0B0B';
    //         case JobEnums.QUEUED:
    //         case JobEnums.PICKING_UP:
    //         case JobEnums.PICKED_UP:
    //         case JobEnums.DELIVERING:
    //         case JobEnums.ARRIVED_AT_DELIVERING:
    //         case JobEnums.ARRIVED_AT_PICKUP:
    //         case JobEnums.ITEM_LOADED:
    //         case JobEnums.ITEM_DELIVERED:
    //             return '#378FFE';
    //         default:
    //             return 'black';
    //     }
    // };

    const renderRow = (info: ProcessedJobDetails) => {
        return (
            <TableRow key={info.id} sx={{ cursor: 'pointer', backgroundColor:"white", '&:hover': {backgroundColor: '#f5f5f5'} }}>
                {columns.map(column => (
                <TableCell 
                    key={column.id} 
                    align="center" 
                    sx={{
                        ...content,
                        color: column.id === 'status' ? getStatusColor(info.status).toString() : 'black',
                        // : column.id === 'status' && info.status === 'cancelled' || info.status === 'return_to_sender' || info.status === 'picked_up_failed' || info.status === 'delivery_failed'  || info.status === 'return_failed' ? '#ff0000'
                        // : column.id === 'status' && info.status === 'queued' || info.status === 'picking_up' ? "#378FFE"
                        // : 'black', // Green color for "item_delivered"
                    }}>
                    {column.id === 'created_at' ? formatDate(info.created_at) : column.id === 'timestamp' && info.timestamp === -1 ? "" : column.id === 'timestamp' ? formatDate(info.timestamp)
                    : column.id === 'status' ? 
                        info.status =  info[column.id].split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase()) //{jobDetails[job.order_number]?.length > 0 ? jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase()) : job.job_status.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())}
                    : info[column.id as keyof ProcessedJobDetails]}
                </TableCell>
            ))}
            </TableRow>
        );
    };
    
    return (
        <Paper elevation={2}  sx={{ display: 'flex', flexDirection: 'column', height: "620px",  borderRadius:"10px", border:"1px solid #E0E0E0", m:'20px auto' }}>
            <TableContainer sx={{ backgroundColor: "#F5F5F5", alignItems: "center", overflowX: "auto" }}>
                <Table stickyHeader>
                    <TableHead sx={{ width: "100%", margin: "auto" }}>
                        <TableRow sx={{ width: "100%", margin: "auto" }}>
                            {columns.map(column => (
                                <TableCell key={column.id} align="center" sx={renderHeader(column)}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {temp.map(info => renderRow(info))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20,]}
                component="div"
                count={totalJobs}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ flexShrink: 0, boxShadow:"none" }} // Prevent pagination from shrinking
            />
        </Paper>
    );
};

 
export default JobTable;
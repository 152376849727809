import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Typography, Box, Container, Link, Stack } from '@mui/material';
import AutoveHalf from './Components/AutoveHalf';
import LoginForm from './Components/LoginForm';
import useWindowSize from '../../components/useWindowSize';
import autove from '../../assets/autove.jpeg';
import mobileDisplay from '../../assets/mobileDisplay.png';
import AppBar from '@mui/material/AppBar';

const Login = () => {
    const {isMobile} = useWindowSize()

    return (
        isMobile ? (
            <Container sx={{ display: 'flex', flexDirection: 'column', maxWidth: "80vh", justifyContent: 'center', backgroundColor: "F5F5F5", color: "black", overflowY: "auto", minHeight: '100vh'}}>
                <Box sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center',minHeight: '150px'}}>
                    <Box sx={{ p: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', zIndex: 2 }}>
                        <img src={autove} alt="Autove Logo" width="50" style={{ borderRadius: "10px" }} />
                        <Typography variant="h4" sx={{ ml: 3, fontFamily: "Poppins", fontWeight: "600", color: "black" }}>
                            Autoves
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0,  display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1, py: 2}}>
                        <img src={mobileDisplay} alt="Display" style={{ width: "95%", objectFit: 'contain' }} />
                    </Box>
                </Box>
                <LoginForm isMobile={isMobile} />
            </Container>

        ) : (
            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', backgroundColor: "#f5f5f5", color: "black", minHeight: '100vh'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt:3, ml:3 }}>
                    <img src={autove} alt="Autove Logo" width="50" style={{borderRadius:"10px"}}/>
                    <Typography variant="h4" sx={{ ml: 3 , fontFamily:"Poppins", fontWeight:"600", color:"black"}}>
                        Autoves
                    </Typography>
                </Box>

                <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: "F5F5F5", alignItems: 'center', margin:"auto"}}>
                    <Paper sx={{ display: 'flex', flexDirection: 'row', height: "600px", borderRadius:"10px", width: "100%", boxShadow: 5 }}>
                        <AutoveHalf />
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 5 }}>
                            <LoginForm isMobile={isMobile} />
                        </Box>
                    </Paper>
                </Container>
            </div>
        )
    );
}
 
export default Login;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
    notificationType: string;
    notificationMessage: string;
    notificationTitle: string;
    isNotificationOn: boolean;
    colorProps: {
        background: string;
        border: string;
    }
}


const initialState: NotificationState = {
    notificationType: '', 
    notificationMessage: '',
    notificationTitle: '',
    isNotificationOn: false,
    colorProps: {
        background: '',
        border: ''
    }
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    toggleNotification: (state) => {
      state.isNotificationOn = !state.isNotificationOn;
    },
    setNotificationOn: (state) => {
      state.isNotificationOn = true;
    },
    setNotificationOff: (state) => {
      state.isNotificationOn = false;
      state.notificationMessage = '';
      state.notificationTitle = '';
      state.notificationType = '';
    },
    setNotificationType: (state, action: PayloadAction<string>) => {
      state.notificationType = action.payload;
    },
    setNotificationMessage: (state, action: PayloadAction<string>) => {
      state.notificationMessage = action.payload;
    },
    setNotificationTitle: (state, action: PayloadAction<string>) => {
      state.notificationTitle = action.payload;
    },
    setNotificationColorProps: (state, action: PayloadAction<{background: string, border: string}>) => {
        state.colorProps = action.payload
    }
  },
});

export const { toggleNotification, setNotificationOn, setNotificationOff, setNotificationType, setNotificationMessage, setNotificationTitle, setNotificationColorProps } = notificationSlice.actions;

export default notificationSlice.reducer;

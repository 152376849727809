import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment, Pagination } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import robotBusy from '../../../assets/robotBusy.png'; 
import flashbot from '../../../assets/flashbot.png'; 
import PlaceIcon from '@mui/icons-material/Place';
import SearchIcon from '@mui/icons-material/Search';
import salad from '../../../assets/salad.png'; 
import checked from '../../../assets/checked.png';
import { robotDetails, listOfJobs } from "../../../utils/DummyData";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fuse from 'fuse.js';
import JobDetails from "./JobDetails";
import { getStatusColor } from "../../../utils/ColourStatus";
import unemployment from '../../../assets/unemployment.png';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPageNumber, setTotalPageNumber } from "../../../store/page/jobPageSlice";
import { JobData2, JobDetails3 } from "../../../store/types";

interface JobDetails {
    orderNo: string;
    timeStamp: string;
    status: string;
    pickUp: string;
    dropOff: string;
    estimatedTime: string;
    currentLocation: string;
    robotName: string;
    robotMacAddr: string;
}

interface JobBoardProps {
    handleJobBoardClose: () => void;
    handleSelectedJob: (job: JobDetails2) => void;
    selectedJob: JobDetails2;
    imageHeight: number;
    activeStep: number;
    handleJobSearchField: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchValue: string;
    listOfJobs: JobData;
    handleBackButton: () => void;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    job_status: string;
    order_number: string;
}

interface JobData {
    [order_number: string]: JobDetails2;
}


const JobBoard: React.FC<JobBoardProps> = ({ handleJobBoardClose, handleSelectedJob, selectedJob, imageHeight, activeStep, handleJobSearchField, searchValue, listOfJobs , handleBackButton}) => {

    const listJobs = Object.keys(listOfJobs).map(order_number => {
        return {
            ...listOfJobs[order_number]
        }
    });

    useEffect(() => {
        console.log('checking', listJobs);
    }, [listJobs]);

    const [tabValue, setTabValue] = useState(0);
    
    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
    }
    // const jobs: JobDetails[] = listOfJobs;

    const fuse = new Fuse(listJobs, {
        keys: ['order_number'],
        threshold: 0.3
    });

    // Get search results if searchValue is not empty
    const searchResults = searchValue ? fuse.search(searchValue).map(result => result.item) : listJobs;

    // Filter based on tabValue and search results
    const tabFilterJobs = searchResults.filter(job =>
        // tabValue === 0 ? job.job_status !== "item_delivered" : job.job_status === "item_delivered"
        tabValue === 1 ? job.job_status === "item_delivered" : 
        tabValue === 2 ? (job.job_status === "picked_up_failed" || job.job_status === "return_to_sender" || job.job_status === "delivery_failed" || job.job_status === "return_failed" || job.job_status === "cancelled"): 
        job.job_status !== "item_delivered" && job.job_status !== "cancelled" && job.job_status !== "picked_up_failed" && job.job_status !== "return_to_sender" && job.job_status !== "delivery_failed" && job.job_status !== "return_failed"
    );

    // sort in descending order

    // filter to only today jobs
    // const todayJobs = tabFilterJobs.filter(job => {
    //     const today = new Date();
    //     const jobDate = new Date(job.timestamp*1000);
    //     return today.toDateString() === jobDate.toDateString();
    // });

    // const filteredJobs = todayJobs.sort((a, b) => b.timestamp - a.timestamp);
    
    // const currentPage = useSelector((state: any) => state.jobPage.currentPage);
    // const jobsPerPage = useSelector((state: any) => state.jobPage.jobsPerPage);

    // Pagination calculations

    // const currentJobs = filteredJobs.slice((currentPage - 1) * jobsPerPage, currentPage * jobsPerPage);


    const dispatch = useDispatch();
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch( setCurrentPageNumber(value) );
    };

    const totalPageNumber = useSelector((state: any) => state.jobPage.totalPageNumber);
    const currentPageNumber = useSelector((state: any) => state.jobPage.currentPageNumber);
    const totalJobNumber = useSelector((state: any) => state.jobPage.totalJobNumber);
    return ( 
        <Stack
            sx={{
                position: "absolute",
                // top: "225px",
                top: selectedJob.order_number !="" ? "225px" : "580px",
                right: "30px",
                width: "400px",
                bgcolor: "white",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                height: selectedJob.order_number !="" ? "450px" : "345px",
                overflowY: "hidden",
                overflowX: "hidden",
            }}>
                {selectedJob.order_number !="" ? 
                    <JobDetails job={selectedJob} handleJobBoardClose={handleJobBoardClose} imageHeight={imageHeight} activeStep={activeStep} handleBackButton={handleBackButton} />
                :
                <>
                    <Box sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",  height: "150px", display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m:1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m:1 }}>
                                <Typography sx={{ color: "#000", ml:1, fontWeight:"600", fontSize:"16px", mt:0.2 }}>
                                    Jobs
                                </Typography>
                                <IconButton onClick={handleJobBoardClose}>
                                    <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                                </IconButton>
                            </Box>
                            <TextField
                                id="search-job"
                                placeholder="Search Job ID?"
                                value={searchValue}
                                onChange={handleJobSearchField}
                                variant="outlined"
                                sx={{
                                    width: "92%",
                                    m: "auto",
                                    '& .MuiInputBase-root': { 
                                        height: '50px', 
                                        fontSize:"14px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#2C8DFF", 
                                        },
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height:"240px",}}>
                                <Tabs 
                                    value={tabValue} 
                                    onChange={(event, newValue) => handleTabChange(newValue)}
                                    sx={{
                                        ml:2,
                                        mt:1,
                                        fontSize: "8px",
                                        '.MuiTabs-indicator': {
                                            display: 'none',  
                                        },
                                        '& .MuiTab-root': {
                                            color: '#A0A0A0', 
                                            fontSize: '12px',
                                            height: '20px', 
                                            minHeight: '20px',
                                            '&.Mui-selected': {
                                                color: '#087D46', 
                                                backgroundColor: '#00E58E',
                                                borderRadius: '30px',
                                            }
                                        }
                                }}>
                                    <Tab label="In Progress" />
                                    <Tab label="Completed" />
                                    <Tab label="Failed" />
                                </Tabs>
                            </Box>
                    </Box>
                </Box>
                <Box sx={{overflowY:"auto"}}>
                    <List >
                        {tabFilterJobs.length > 0 ? tabFilterJobs.map((job, index) => (
                            <>
                                <ListItem key={index} sx={{ py: 1, '&:hover': { backgroundColor: '#f4f4f4', cursor: 'pointer' }, paddingLeft: "35px"}} onClick={() => handleSelectedJob(job)}>
                                    <ListItemIcon>
                                        <img src={salad} style={{ width: "30px", height: "30px" }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }}>
                                            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>    
                                                <Typography sx={{ color: "#000", fontSize:"16px", fontWeight: "500" }}>
                                                    Job #{job.order_number.slice(-5).toUpperCase()}
                                                </Typography>
                                                <Typography sx={{ color: "#727272", fontSize:"12px" }}>
                                                    {/* convert timestamp keep time to pm*/}
                                                    Today, {new Date(job.timestamp*1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                                </Typography>
                                            </Box>
                                            <Typography sx={{ color: getStatusColor(job.job_status), fontSize:"12px" }}>
                                                {/* prettify job_status */}
                                                {/* {job.job_status === "item_delivered" ? "Completed" : "In Progress"} */}
                                                {job.job_status.replace(/_/g, " ").split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                                {index < tabFilterJobs.length - 1 && <Divider sx={{ width: "85%", margin:"auto", }} />}
                            </>
                        )) :
                        <Box sx={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: '100%', mt: 1 }}>
                            <img src={unemployment} style={{ width: "100px", height: "100px" }} />
                            <Typography sx={{ color: "#000", fontSize:"18px", mt: 1.5}}>
                                {tabValue === 0 ? "No Jobs in Progress" : tabValue === 1 ? "No Completed Jobs" : "No Failed Jobs"}
                            </Typography>
                        </Box>
                        }
                    </List>
                    {tabFilterJobs.length > 0 &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <Pagination 
                                count={totalPageNumber}
                                page={currentPageNumber} 
                                onChange={handlePageChange} 
                                color="primary" 
                            />
                        </Box>
                    }
                </Box>
                </>
            }
        </Stack>
     );
}
 
export default JobBoard;

// onClick = {() => handleSelectedJob(job)}
import { Map, Marker } from "mapbox-gl";
import OverlaySetting from "../models/OverlaySetting";
import React, { useEffect, useMemo } from "react";
import { GIS } from "../RobotMap";
import { createRoot } from "react-dom/client";
import { Box, Typography } from "@mui/material";
import RobotIcon from "../../icons/RobotIcon";
import batteryFull from '../../../assets/batteryFull.png';
import { Robot } from "../models/Robot";
import { useSelector } from "react-redux";

export const useRenderGISElementOnMap = (
  map: React.MutableRefObject<Map | null>,
  elements: React.ReactElement<GIS>[]
) => {
  useEffect(() => {
    // Do not initialize until map is loaded
    if (!map.current) return;
    const mapboxMap = map.current as Map;
    const markers: Marker[] = [];

    for (const elem of elements) {
      const el = document.createElement("div");
      el.className = "marker";
      markers.push(new Marker(el).setLngLat(elem.props.GIS).addTo(mapboxMap));
      createRoot(el).render(elem);
    }

    return () => {
      markers.forEach((marker) => marker.remove());
    };
  }, [elements, map]);
};

export const useRenderRobotOnMap = (
  map: React.MutableRefObject<Map | null>,
  robots: Robot[],
  onRobotClick?: (robot: Robot) => void
) => {
  useEffect(() => {
    // Do not initialize until map is loaded
    if (!map.current) return;
    console.log("checking 22",robots);
    var markers: Marker[] = [];
    const mapboxMap = map.current as Map;
    for (const robot of robots) {
      console.log("checking 23",robot);
      const el = document.createElement("div");
      el.className = "marker";
      createRoot(el).render(
        <Box onClick={() => onRobotClick && onRobotClick(robot)}> 
          <RobotIcon 
            robot={{
              name: robot.robotName,
              status: robot.status,
              battery: robot.batteryLevel,
            }}
          />
        </Box>
      );

      markers.push(new Marker(el).setLngLat(robot.LatLng).addTo(mapboxMap));
    }

    let requestId: number;
    function animateMarker(timestamp: any) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setLngLat(robots[i].LatLng);
        markers[i].setRotation(robots[i].direction);
        // Rotate the robot icon to be upright
        markers[i]
          .getElement()
          ?.children.item(0)
          ?.children.item(0)
          ?.children.item(0)
          ?.setAttribute("style", `transform: rotate(${robots[i].north}deg)`);
        // make sure robot is always on top
        markers[i].getElement()?.style.setProperty("z-index", "1000");
        }

      requestId = requestAnimationFrame(animateMarker);
    }
    requestId = requestAnimationFrame(animateMarker);

    return () => {
      cancelAnimationFrame(requestId);
      markers.forEach((marker) => marker.remove());
    };
  }, [robots, map]);
};


export const useRenderLinesOnMap = (
  map: React.MutableRefObject<Map | null>,
  line: [number, number][],
  lineWidth: number = 8,
  lineColor: string = "#888"
) => {
  useEffect(() => {
    if (!map.current || line.length === 0) return;

    const mapboxMap = map.current as Map;

    mapboxMap.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: line,
        },
      },
    });
    mapboxMap.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": lineColor,
        "line-width": lineWidth,
      },
    });

    return () => {
      mapboxMap.removeLayer("route");
      mapboxMap.removeSource("route");
    };
  }, [line, map, lineColor, lineWidth]);
};

export const loadOverlay = (
  map: React.MutableRefObject<Map | null>,
  overlaySetting: OverlaySetting,
  name: string
) => {
  const mapboxMap = map.current as Map;

  mapboxMap.addSource(name, {
    type: "image",
    url: overlaySetting.url,
    coordinates: [
      [overlaySetting.topLeftLng, overlaySetting.topLeftLat],
      [
        overlaySetting.topLeftLng + overlaySetting.widthInGIS,
        overlaySetting.topLeftLat,
      ],
      [
        overlaySetting.topLeftLng + overlaySetting.widthInGIS,
        overlaySetting.topLeftLat - overlaySetting.heightInGIS,
      ],
      [
        overlaySetting.topLeftLng,
        overlaySetting.topLeftLat - overlaySetting.heightInGIS,
      ],
    ],
  });
  
  mapboxMap.addLayer({
    id: name,
    type: "raster",
    source: name,
  });
};


import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../context/AuthContext';

interface CustomAvatarProps {
    initials: string;
}

const CustomAvatar: React.FC <CustomAvatarProps> = ({ initials }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { logout } = useAuth();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        logout();
        console.log("Logged out");
    };

    return (
        <>
            <Avatar
                sx={{ bgcolor: "#3F97FF", color: "white", cursor: 'pointer',  }}
                onClick={handleClick}
            >
                {initials}
            </Avatar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        mt:1,
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                backgroundColor: '#f5f5f5',
                            },
                            backgroundColor: '#fff',
                        },
                    },
                }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </MenuItem>
            </Menu>
        </>
    );
}

export default CustomAvatar;

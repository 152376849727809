import React, { useEffect, useRef, useState } from "react";
import RobotMap from "../../../components/RobotMap/RobotMap";
import { listOfRobots } from "../../../utils/DummyData";
import flashbot from '../../../assets/flashbot.png'; 
import { Robot } from "../../../components/RobotMap/models/Robot";
import sgh3 from '../../../assets/sg3.png';

export interface RobotMapContainerProps {
  onRobotClick: (robot: Robot) => void;
  children?: JSX.Element[];
  line?: [number, number][];
  movement: [number, number][];
  robotData: RobotsData;
  renderLocationMarkers: () => JSX.Element[];
}


interface JobDetails2 {
  compartment_pin: string;
  id: number;
  job_type: string;
  job_id: number;
  organisation_id: string;
  load_compartment: number;
  recipient_id: string;
  recipient_location: string;
  recipient_name: string;
  robot_id: string;
  sender_id: string;
  sender_location: string;
  sender_name: string;
  timestamp: number;
  order_number: string;
  job_status: string;
}

interface RobotPose {
  latitude: number;
  longitude: number;
  orientation: number;
}

interface RobotStatus2 {
  battery: number;
  can_assign_job: boolean;
  is_online: boolean;
  map: string;
  organisation: string;
  pose: RobotPose;
  robot_id: string;
  robot_state: string;
  robot_type: string;
  timestamp: number;  
  robot_name: string;
  robot_serial_number: string;
}

interface GeneralRobotInfo {
  robot_name: string;
  robot_mac: string;
  robot_serial_number: string;
  robot_type: string;
  organisation: string;
}

interface RobotInfo {
  robotStatus?: RobotStatus2;
  jobs?: JobData;
  generalInfo: GeneralRobotInfo;
}

interface RobotsData {
  [robot_id: string]: RobotInfo;
}

interface JobData { 
  [job_id: string]: JobDetails2;
}


const RobotMapContainer: React.FC<RobotMapContainerProps> = ({
  onRobotClick,
  children,
  line,
  movement,
  robotData,
  renderLocationMarkers,
}) => {
  const [robots, setRobots] = useState<Robot[]>([]);
  // const [lng, setLng] = useState(103.78681142555857);
  // const [lat, setLat] = useState(1.2973538783600418);

  const [lng, setLng] = useState(103.83484878754473);
  const [lat, setLat] = useState(1.2782008843142307);
  //Setting for map overlay
  // const mapOverlaySetting = {
  //   url: "assets/autove-floorplan.png",
  //   topLeftLng: 103.78595945422515,
  //   topLeftLat: 1.2976129210499472,
  //   widthInGIS: 0.0011181175118634928,
  //   heightInGIS: 0.0005332418731876894,
  // };

  const mapOverlaySetting = {
    url: "assets/sgh3.png",
    topLeftLng:  103.83414353971204,
    topLeftLat: 1.2795982810092200,
    widthInGIS: 0.0015776593216401125,
    heightInGIS: 0.001921218692402249,
  };
  

  
  useEffect(() => {
    console.log("Robot data:", robotData[Object.keys(robotData)[0]]?.robotStatus?.pose);
    const updatedRobots = Object.values(robotData).map(robotDetails => {
      const existingRobot = robots.find(r => r.robotId === robotDetails.robotStatus?.robot_id);
      if (existingRobot && robotDetails.robotStatus){
        console.log("Updating existing robot")
        // Update existing robot's target position
        existingRobot.updateTarget([
          robotDetails.robotStatus.pose.longitude,
          robotDetails.robotStatus.pose.latitude
        ], robotDetails.robotStatus.pose.orientation);

        existingRobot.batteryLevel = robotDetails.robotStatus.battery;
        existingRobot.status = robotDetails.robotStatus.robot_state;
        existingRobot.robotName = robotDetails.robotStatus.robot_name;
        return existingRobot;
      } else if (robotDetails.robotStatus) {
        // Create new robot
        console.log("Creating new robot");
        return new Robot(
          "assets/robot.png",
          [robotDetails.robotStatus.pose.longitude, robotDetails.robotStatus.pose.latitude],
          robotDetails.robotStatus
        );
      }
      return null;
    }).filter(Boolean);
    
    // setRobots(updatedRobots);
    setRobots(updatedRobots.filter(robot => robot !== null) as Robot[]);
    console.log("Updated robots pose:", updatedRobots[0]?.LatLng, updatedRobots[0]?.targetLatLng);
    
  }, [robotData]); // Depend on robotData to update robots

  var requestId: number;
  const moveRobots = () => {
    robots.forEach(robot => robot.move());
    requestId = requestAnimationFrame(moveRobots);
  };

  useEffect(() => {
    console.log("Starting animation!!!!!");
    requestId = requestAnimationFrame(moveRobots);
    return () => cancelAnimationFrame(requestId);
  }, [robots]); // Rerun animation when robots update

  return (
    <RobotMap
      startLocation={[lng, lat]}
      zoomLevel={18}
      mapOverlay={mapOverlaySetting}
      robots={robots}
      line={line}
      onRobotClick={onRobotClick}
      renderLocationMarkers={renderLocationMarkers}
    >
      {children}
      {/* {renderLocationMarkers()} */}
    </RobotMap>
  );
};

export default RobotMapContainer;
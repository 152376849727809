import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment, Stepper, Step, StepLabel, styled, StepConnector, StepContent } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import salad from '../../../assets/salad.png'; 
import { useWebSocket } from "../../../components/useWebSocket";
import { JobEnums } from "../../../utils/enums/JobEnums";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getStatusColor } from "../../../utils/ColourStatus";
import { JobData2, JobDetails3 } from "../../../store/types";
interface JobDetailsProps {
    imageHeight: number;
    job: JobDetails2;
    handleJobBoardClose: () => void;
    activeStep: number;
    handleBackButton: () => void;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    job_status: string;
    order_number: string;
}

interface Step {
    label: string;
    text: string;
    time: string;
    id: string;
}

interface JobHistory {
    [order_number: string]: JobDetails2[];
}


const JobDetails: React.FC<JobDetailsProps> = ({ job, handleJobBoardClose, imageHeight, activeStep, handleBackButton }) => {

    const statusToStepIndex = {
        [JobEnums.PICKING_UP]: 0,
        [JobEnums.ARRIVED_AT_PICKUP]: 1,
        [JobEnums.ITEM_LOADED]: 2,
        [JobEnums.DELIVERING]: 3,
        [JobEnums.ARRIVED_AT_DELIVERING]: 4,
        [JobEnums.ITEM_DELIVERED]: 5,
    };

    const [jobDetails, setJobDetails] = useState<JobHistory>({});
    const [currentStep, setCurrentStep] = useState(-1);
    const [steps3, setSteps3] = useState<Step[]>([
        { label: "Robot Picking Up Order", text: "Robot is coming to pick up your order", time: "...", id: JobEnums.PICKING_UP  },
        { label: "Robot at Pick Up", text: "Robot has arrived to pick up your order", time: "..." , id: JobEnums.ARRIVED_AT_PICKUP},
        { label: "Order Picked Up", text: "Your item has been picked up", time: "..." , id: JobEnums.ITEM_LOADED},
        { label: "Dispatching", text: "Your item is on the way", time: "..." , id: JobEnums.DELIVERING},
        { label: "Robot Arrived", text: "Your item has arrived", time: "..." , id: JobEnums.ARRIVED_AT_DELIVERING},
        { label: "Delivered", text: "Your item has been delivered", time: "..." , id: JobEnums.ITEM_DELIVERED}
    ]);

    const handleJobHistoryUpdates = useCallback((data: JobDetails2) => {
        console.log("Job history updates:", data)
        setJobDetails(prevJobDetails => {
            const existingJobs = prevJobDetails[data.order_number] || [];
            if (!existingJobs.find(job => job.timestamp === data.timestamp && job.job_status === data.job_status)) {
                return {
                    ...prevJobDetails,
                    [data.order_number]: [...existingJobs, data]
                };
            }
            return prevJobDetails;
        });
    }, []);

    useEffect(() => {
        console.log("Job details:", jobDetails);
        console.log("Job:", job);
    }, [jobDetails]);


    useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/delivery/${job.job_id}/`, handleJobHistoryUpdates);

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',     
            month: 'short',     
            year: 'numeric'     
        };
        return date.toLocaleDateString('en-GB', options);
    };
    
    // useEffect(() => {
    //     if (jobDetails[job.order_number]?.length > 0) {
    //         const sortedJobs = jobDetails[job.order_number].sort((a, b) => a.timestamp - b.timestamp);
    //         const newSteps = sortedJobs.map(job => ({
    //             label: job.job_status.replace(/_/g, ' ').toUpperCase(),
    //             text: text[statusToStepIndex[job.job_status] || 0],
    //             time: formatDate(job.timestamp) + " " + new Date(job.timestamp * 1000).toLocaleTimeString(),
    //             id: statusToStepIndex[job.job_status] || 0,
    //         }));
    //         setSteps2(newSteps.sort((a, b) => a.time.localeCompare(b.time)));
    //         setCurrentStep(statusToStepIndex[job.job_status] || 0);
    //         console.log("New steps:", newSteps);
    //         console.log("Current step:", currentStep);
    //     }
    // }, [jobDetails, job.order_number]);

    useEffect(() => {
        if (jobDetails[job.job_id]?.length > 0) {
            const updatedSteps = steps3.map(step => {
                const jobInfo = jobDetails[job.job_id].find(j => j.job_status === step.id);
                console.log(jobInfo);
                return jobInfo ? {
                    ...step,
                    time: `${formatDate(jobInfo.timestamp)}, ${new Date(jobInfo.timestamp * 1000).toLocaleTimeString()}`
                } : step;
            });
            console.log("Updated steps:", updatedSteps);
            setCurrentStep(statusToStepIndex[jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status] || -1);

            // job status is picked up failed, cancelled or queued, set current step to -1
            if (jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status === JobEnums.PICKED_UP_FAILED 
                || jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status === JobEnums.CANCELLED 
                || jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status === JobEnums.QUEUED 
                ) {
                setCurrentStep(-1);
            }

            // job status delivery failed, return failed, return to sender, set current step to the the step before failed
            if (jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status === JobEnums.DELIVERY_FAILED 
                || jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status === JobEnums.RETURN_FAILED 
                || jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status === JobEnums.RETURN_TO_SENDER 
                ) {
                setCurrentStep(statusToStepIndex[jobDetails[job.job_id][jobDetails[job.job_id].length - 2].job_status] - 1);
            }

            // if item delivered, +1 to current step
            if (jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status === JobEnums.ITEM_DELIVERED) {
                setCurrentStep(statusToStepIndex[jobDetails[job.job_id][jobDetails[job.job_id].length - 1].job_status] + 1 || -1);
            }
            
            setSteps3(updatedSteps);
        }
    }, [jobDetails]);


    const CustomConnector = styled(StepConnector)(({ theme }) => ({
        '& .MuiStepConnector-line': {
            borderColor: '#9F9F9F', // Set the color of the connector
            borderWidth: 5, // Increase the thickness of the line
            borderTopWidth: 15,
            height: 75,
            left: 10,
        },
    }));

    return ( 
        <>
            <Box sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",  height: "230px", display: "flex", flexDirection: "column",}}>
                <Stack sx={{
                    position: "absolute",
                    top: "20px",
                    left: "15px",
                }}>
                    <IconButton onClick={handleBackButton}>
                        <ArrowBackIosNewIcon sx={{ color: "#000", fontSize:"15px" }} />
                    </IconButton>
                </Stack>
                <Stack
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "10px",
                    m:1.5,
                    ml:4.5

                }}>
                <Stack sx={{width: "60px", height:"60px", bgcolor:"#fff", color: "white", fontWeight:"600", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center" }}>
                    <img src={salad} style={{ width: "50px", height: "50px" }} />
                </Stack>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", m:1, ml:4 }}>
                    <Typography sx={{ color: "#000", fontSize:"20px", fontWeight:"600" }}>
                        Order #{job.order_number.slice(-5).toUpperCase()}
                    </Typography>
                    <Typography sx={{ color: getStatusColor(job.job_status), fontSize:"14px" }}>
                        {/* {jobDetails[job.order_number]?.length > 0 ? jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status.split('_').join(' ') : job.job_job_status.split('_').join(' ')} */}
                        {jobDetails[job.order_number]?.length > 0 ? jobDetails[job.order_number][jobDetails[job.order_number].length - 1].job_status.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase()) : job.job_status.split('_').join(' ').replace(/\b\w/g, char => char.toUpperCase())}

                    </Typography>
                </Box>
                <Stack sx={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                }}>
                    <IconButton onClick={handleJobBoardClose}>
                        <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                    </IconButton>
                </Stack>
            </Stack>
            
            <Box sx={{ display: "flex", flexDirection: "row", width: "85%", margin:"0 auto", justifyContent:"space-between", mt:-1 }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                    <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                        Robot
                    </Typography>
                    <Typography sx={{ color: "#000", fontSize:"14px" }}>
                        {job.robot_id ? job.robot_id : "Not Assigned"}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                    <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                        Pick Up
                    </Typography>
                    <Typography sx={{ color: "#000", fontSize:"14px" }}>
                        {job.sender_location}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                    <Typography sx={{ color: "#9F9F9F", fontSize:"12px" }}>
                        Drop Off
                    </Typography>
                    <Typography sx={{ color: "#000", fontSize:"14px" }}>
                        {job.recipient_location}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{display: "flex", flexDirection: "row", ml: 3, mt: 2, mb:2}}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "left" }}>
                    <PlaceIcon sx={{ color: "#9F9F9F", fontSize:"20px", mr: 1.5 }} />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                        <Typography sx={{ color: "#000", fontSize:"14px" }}>
                            Autove
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize:"12px" }}>
                            {/* {job.currentLocation} */}
                            #01-07
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "left", ml:9 }}>
                    <AccessTimeIcon sx={{ color: "#9F9F9F", fontSize:"20px", mr: 1.5, }} />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                        <Typography sx={{ color: "#000", fontSize:"14px" }}>
                            Estimated Time
                        </Typography>
                        <Typography sx={{ color: "#000", fontSize:"12px" }}>
                            {/* {job.estimatedTime} */}
                            20mins
                        </Typography>
                    </Box>
                </Box>
            </Box>
            </Box>
            <Box sx={{overflow:"auto"}}>
            <Box sx={{ display: "flex", flexDirection: "row", width: "90%", margin:"0 auto", justifyContent:"space-between", mt:2 }}>
            <Stepper activeStep={currentStep} orientation="vertical" sx={{ padding: 2, }} connector={<CustomConnector />}>
                {steps3.map((step, index) => (
                    <Step key={index} completed={currentStep > index}  sx={{mt:-6.2, mb:-6.2, ml:0.2}} >
                        <StepLabel sx={{
                            '& .MuiStepLabel-label': {
                                textAlign: 'left',
                                mt: 5, 
                                ml:2,
                                fontSize: "16px"

                            },
                            '& .MuiStepConnector-line': {
                                borderColor: '#9F9F9F', 
                                borderWidth: 5, 
                            },
                            }}>
                            {step.label}
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5, fontSize: "14px" }}>
                                {step.text}
                                <br />
                                {step.time}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            </Box>
        </Box>
        </>
     );
}
 
export default JobDetails;
import React, { useEffect, useState } from 'react';
import { Box, Typography, AppBar, Toolbar, Icon , IconButton, Badge} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomAvatar from '../CustomAvatar';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggle, setTrue } from '../../store/deliveryDashboardSlice';
import { useSelector } from 'react-redux';

interface TopBarProps {
    handleIsOpen: () => void;
    handleNotificationDisplay: () => void;
    notificationCount: number;
    toggleSidebar: () => void;
}

const TopBar: React.FC<TopBarProps> = ({  handleIsOpen, handleNotificationDisplay, notificationCount, toggleSidebar }) => {
    const isOpen = useSelector((state: any) => state.deliveryDashboard.isOpen);
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState("");
    const [profile, setProfile] = useState<{ username: string } | null>(null);
    const location = useLocation();
    const [isDashboard, setIsDashboard] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("location", location);
        if (location.pathname === '/') {
            setIsDashboard(true);
        }
        else {
            setIsDashboard(false);
        }
        console.log("isDashboard", isDashboard);
    }, [location]);

    useEffect(() => {
        // Load profile data from localStorage
        const loadProfile = () => {
            const storedProfile = localStorage.getItem('profile');
            if (storedProfile) {
                setProfile(JSON.parse(storedProfile));
            }
        };

        loadProfile();

        // Update current time every second
        const updateCurrentTime = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, "0");
            const minutes = now.getMinutes().toString().padStart(2, "0");
            const seconds = now.getSeconds().toString().padStart(2, "0");
            setCurrentTime(`${hours}:${minutes}:${seconds}`);
        };

        updateCurrentTime();
        const intervalId = setInterval(updateCurrentTime, 1000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <AppBar position="sticky" sx={{
            bgcolor: "white",
            boxShadow: "none",
            background: location.pathname === '/' ? "#DAFBEF" : "linear-gradient(to bottom, #DAFBEF, #F8F7F7)",
        }}>
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '98%', margin: "auto" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={toggleSidebar} sx={{ mr:4 }}>
                        {/* <IconButton sx={{ mr:4 }}> */}

                            <MenuIcon sx={{ color: "#444444", fontSize: "25px",}} />
                        </IconButton>
                        <Typography variant="h6" sx={{ color: "#444444", mr: 4, fontSize: "24px" }}>
                            RoboHub
                        </Typography>
                        {isDashboard ? (
                            <>
                                <Typography variant="h6" sx={{ color: isOpen ? "#000" : "#A7A7A7", mr: 4, fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} onClick={() => dispatch(toggle())}>
                                    Delivery
                                </Typography>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#A7A7A7" , mr: 4, fontSize:"14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} >
                                    Food
                                </Typography>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#A7A7A7" , mr: 4, fontSize:"14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} >
                                    Cleaning
                                </Typography>
                            </>
                        ): 
                            <>
                                <Typography variant="h6" sx={{ color: "#A7A7A7", mr: 4, fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} onClick={() => navigate('/')}>
                                    Dashboard
                                </Typography>
                                <Typography variant="h6" sx={{ color: location.pathname === "/jobs"? "#000":"#A7A7A7", mr: 4, fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} onClick={() => navigate('/jobs')}>
                                    Jobs
                                </Typography>
                                <Typography variant="h6" sx={{ color: location.pathname === "/reports"? "#000":"#A7A7A7", mr: 4, fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} onClick={() => navigate('/reports')}>
                                    Reports
                                </Typography>
                                <Typography variant="h6" sx={{ color: location.pathname === "/robots"? "#000":"#A7A7A7", mr: 4, fontSize: "14px", '&:hover': { cursor: 'pointer', color: "#4e4e4e" } }} >
                                    Robots
                                </Typography>
                            </>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ mr: 2, color: "#444444" }}>
                            {currentTime}
                        </Typography>
                        <IconButton onClick={handleNotificationDisplay}>
                        <Badge badgeContent={notificationCount} color="primary">
                            <NotificationsNoneIcon sx={{ color: "#444444" }} />
                        </Badge>
                        </IconButton>
                        <SettingsIcon sx={{ color: "#444444", ml: 2, mr: 2 }} />
                        {profile && (
                            <>
                                <CustomAvatar initials={profile.username[0] || ''} />
                                <Typography variant="h6" sx={{ color: "#444444", ml: 2 }}>
                                    {profile.username}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default TopBar;

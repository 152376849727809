import React, { createContext, useContext, useState, useEffect, ReactNode, FC } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  isLoggedIn: boolean;
  isLoading: boolean;
  login: (accessToken: string, refreshToken:string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

// AuthProvider implementation
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {

  useEffect(() => {
    console.log("Checking user validity");
    // checkUserValidity();
    return () => {
      console.log("Cleaning up");
    };
  }, []);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    checkUserValidity();
  }, []);

  const checkUserValidity = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      verifyToken(accessToken);
    } else {
      setIsLoading(false);
    }
  };

  const verifyToken = async (accessToken: string) => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/auth/login/refresh/`,
      headers: {
          "Authorization": `Bearer ${accessToken}`,
      },
      data:{"refresh": localStorage.getItem('refreshToken')}
    }).then((response) => {
      if (response.status === 200) {
        console.log("Logged in");
        console.log(response.data);
        setIsLoggedIn(true);
        if (window.location.pathname === '/login') navigate('/');
      } else {
        localStorage.removeItem('accessToken');
        setIsLoggedIn(false);
      }
      setIsLoading(false);
    })
    .catch((error) => {
      console.error(error);
      localStorage.removeItem('accessToken');
      setIsLoggedIn(false);
      setIsLoading(false);
    });
  };

  const login = async (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    verifyToken(refreshToken);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('profile');
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, isLoading, login, logout }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

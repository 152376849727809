import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
};

export const deliveryDashboardSlice = createSlice({
    name: 'deliveryDashboard',
    initialState,
    reducers: {
        toggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        setTrue: (state) => {
            state.isOpen = true;
        },
        setFalse: (state) => {
            state.isOpen = false;
        }
    },
});

export const { toggle, setFalse, setTrue } = deliveryDashboardSlice.actions;

export default deliveryDashboardSlice.reducer;

import React from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Typography, Box } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import salad from '../../../assets/salad.png'; 
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import { JobData2, JobDetails3 } from '../../../store/types';
interface NotificationHistoryPanelProps {
    open: boolean;
    onClose: () => void;
    dummyNotificationHistory: JobData;
    handleSelectJob: (job: JobDetails2) => void;
    handleDeleteNotification: (order_number: string) => void;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface JobData {
    [order_number: string]: JobDetails2;
}


const NotificationHistoryPanel: React.FC<NotificationHistoryPanelProps> = ({ open, onClose, dummyNotificationHistory, handleSelectJob, handleDeleteNotification }) => {

    const listJobs = Object.keys(dummyNotificationHistory).map(order_number => {
        return {
            ...dummyNotificationHistory[order_number]
        }
    });

    // sort by timestamp
    listJobs.sort((a, b) => b.timestamp - a.timestamp);
    
    return (  
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box
                sx={{ width: 350 }}
                role="presentation"
                onClick={onClose}
                onKeyDown={onClose}
            >
                <Box sx={{ display: "flex", flexDirection: "row", margin:"auto", mt:2, ml:1}}>
                    <IconButton onClick={onClose} sx={{mt:-0.8}}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h5" sx={{ fontSize: "24px" , ml: 2}}>
                        Notifications
                    </Typography>
                    
                </Box>
                {/* Add your notification content here */}
                <List >
                        {listJobs.map((job, index) => (
                            <>
                                <ListItem key={index} sx={{ py: 1, '&:hover': { backgroundColor: '#f4f4f4', cursor: 'pointer' }, paddingLeft: "20px"}} >
                                    <ListItemIcon>
                                        <img src={salad} style={{ width: "30px", height: "30px" }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }}>
                                                <Typography sx={{ color: "#000", fontSize:"16px", fontWeight: "500" }}>
                                                    Order #{job.order_number.slice(-5)}
                                                </Typography>
                                                <Typography sx={{ color: "#727272", fontSize:"12px" }}>
                                                        {/* convert timestamp keep time to pm*/}
                                                    {new Date(job.timestamp*1000).toLocaleDateString()}, {new Date(job.timestamp*1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}

                                                </Typography>
                                                <Typography sx={{ color: job.job_status !== "item_delivered"? "#378FFE": "#23C666", fontSize:"12px" }}>
                                                    {/* prettify status */}
                                                    {/* {job.job_status === "item_delivered" ? "Completed" : "In Progress"} */}
                                                    {job.job_status.replace(/_/g, " ").split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                                </Typography>
                                            </Box>
                                            <IconButton sx={{fontSize: "20px", color: "#a7a7a7", mt: -1, height:"40px"}}  onClick={(e) => {e.stopPropagation(); handleDeleteNotification(job.order_number)} }>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                                {index < listJobs.length - 1 && <Divider sx={{ width: "95%", margin:"auto", }} />}
                            </>
                        ))}
                    </List>
            </Box>
        </Drawer>
        );
}
 
export default NotificationHistoryPanel;

import React, { useEffect, useState } from "react";
import { Box, Button, Typography, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Card } from "@mui/material";
import flashbotCrop from '../../../assets/flashbotCrop.png';
import { listOfAvailableRobots } from "../../../utils/DummyData";

interface OtherDetailsProps {
    timeFocused: boolean;
    handleTimeFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleTimeBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    fieldCompleted: boolean;
    form: {
        pickUp: string;
        dropOff: string;
        time: string;
        robot: string;
        waitTime: string;
        phoneNumber: string;

        recipient_name: string;
        recipient_location: string;
        compartment_pin: string;
        sender_location: string;
        sender_name: string;
        job_type: string;
        robot_id: string;
        required_compartment: number;
        timestamp: number;
        sender_id: string;
        recipient_id: string;
        organisation_id: string;
    };
    handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    robots: RobotsData;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}
interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
}


interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [order_number: string]: JobDetails2;
}

interface JobHistory {
    [order_number: string]: JobDetails2[];
}

interface Robots {
    name: string;
    description?: string;
    serialNo?: string;
    location?: string;
    status?: string;
    imageUrl?: string;
    isRobot: boolean;
}

const OtherDetails: React.FC<OtherDetailsProps> = ({ timeFocused, handleTimeFocus, handleTimeBlur, fieldCompleted, form, handleFieldChange, robots }) => {
    // const robotList: Robots[] = listOfAvailableRobots;
    const robotList : Robots[] = [];
    robotList.push({ name: "Call Nearest Robot", description: "Priority call the fastest responding robot", isRobot: false, serialNo: "" });
    Object.keys(robots).forEach((robot_id) => {
        const robot = robots[robot_id];
        robotList.push({
            name: robot.generalInfo.robot_name ? robot.generalInfo.robot_name : "Robot",
            serialNo: robot.generalInfo.robot_mac,
            // location: robot.robotStatus?.pose.latitude + ", " + robot.robotStatus?.pose.longitude,
            status: robot.robotStatus?.robot_state === "idle" ? "Idle" : robot.robotStatus?.robot_state === "busy" ? "Busy" : robot.robotStatus?.robot_state === "charging" ? "Charging" : robot.robotStatus?.robot_state === "estop" ? "Emergency Stop" : "Offline",
            imageUrl: flashbotCrop,
            isRobot: true
        });
    });

    useEffect(() => {
        console.log("checking form" , form);
    }, []);
    

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "row", width: "97%" }}>
                {timeFocused || form.time !="Pick Up Now" && form.time !="" ? (
                    <TextField 
                        name="time"
                        type="time"
                        id="time"
                        value={form.time}
                        onFocus={handleTimeFocus}
                        onBlur={handleTimeBlur}
                        onChange={handleFieldChange}
                        variant="outlined"
                        sx={{ width: "100%", ml: 7.5, mr: 2 }}
                    />
                ) : (
                    <TextField 
                        value="Pick up now"
                        onFocus={handleTimeFocus}
                        onBlur={handleTimeBlur}
                        variant="outlined"
                        sx={{ width: "100%", ml: 7.5, mr: 2 }}
                    />
                )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m:1 }}>
                <Typography sx={{ color: "#000", ml:2, mt:2, fontSize:"14px", fontWeight:"600" }}>
                    Recipient's Number
                </Typography>
                <TextField 
                    name="phoneNumber"
                    id="phoneNumber" 
                    placeholder="Enter Phone Number" 
                    value={form.phoneNumber}
                    onChange={handleFieldChange}
                    variant="outlined"
                    sx={{ 
                        width: "90%", 
                        ml: 2, 
                        mt:1,
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#2C8DFF",
                            },
                        },
                    }} 
                />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m:1 }}>
                <Typography sx={{ color: "#000", ml:2, mt:2, fontSize:"14px", fontWeight:"600" }}>
                    Drop-Off Wait Time
                </Typography>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="wait-time"
                        id="waitTime"
                        name="waitTime"
                        value={form.waitTime}
                        onChange={handleFieldChange}
                        sx={{ display: "flex", flexDirection: "row", ml:2, mt:1, color:"#000" }}
                    >
                        <FormControlLabel value="10mins" control={<Radio />} label="Wait 10 mins" />
                        <FormControlLabel value="indefinitely" control={<Radio />} label="Wait indefinitely" />
                    </RadioGroup>
                </FormControl>
            </Box>

            {fieldCompleted && (
                <Box sx={{ display: "flex", flexDirection: "column", m:1 }}>
                    <Typography sx={{ color: "#000", ml:2, mt:1, fontSize:"14px", fontWeight:"600" }}>
                        Select Robot
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup name="robot_id" id="robot_id" value={form.robot_id} onChange={handleFieldChange}>
                            {robotList.map((robot, index) => (
                                <Card key={index} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", m: 1, p: 1.5, ml:2, mr:2, bgcolor: "#white", borderRadius: "4px", border: "1px solid #E0E0E0" }}>
                                    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", mr: 2 }}>
                                        <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>{robot.name}</Typography>
                                        {robot.isRobot ? (
                                            <>
                                                <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{`Serial No: ${robot.serialNo}`}</Typography>
                                                {/* <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{`Location: ${robot.location}`}</Typography> */}
                                                <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{`Status: ${robot.status}`}</Typography>
                                            </>
                                        ) : (
                                            <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{robot.description}</Typography>
                                        )}
                                    </Box>
                                    {robot.imageUrl && <img src={robot.imageUrl} alt={robot.name} style={{ width: "85px" }} />}
                                    <FormControlLabel
                                        key={index}
                                        value={robot.serialNo }
                                        control={<Radio />}
                                        label=""
                                        sx={{ mr: 1 }}
                                    />
                                </Card>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            )}

            <Box sx={{ display: "flex", flexDirection: "row", width: "97%", margin: "auto", mt: 4, ml:3, mb:2 }}>
                <Button type="submit" variant="contained" disabled={!fieldCompleted } sx={{ width: "100%", bgcolor: "#2C8DFF", color: "white", fontWeight:"600", mr:4}}>
                    Confirm Delivery
                </Button>
            </Box>
        </>
    );
};

export default OtherDetails;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface JobPageState {
    totalPageNumber: number;
    currentPageNumber: number;
    totalJobNumber: number;
    jobsPerPage: number;
    
    totalRobotJobs: number;
    totalRobotJobsPerPage: number;
    totalRobotJobsPageNumber: number;
    currentRobotJobsPageNumber: number;

    totalReportNumber: number;
    totalReportPerPage: number;
    totalReportPageNumber: number;
    currentReportPageNumber: number;
}

const initialState: JobPageState = {
    totalJobNumber: 0,
    totalPageNumber: 0,
    currentPageNumber: 1,
    jobsPerPage: 10,

    totalRobotJobs: 0,
    totalRobotJobsPerPage: 10,
    totalRobotJobsPageNumber: 0,
    currentRobotJobsPageNumber: 1,

    totalReportNumber: 0,
    totalReportPerPage: 10,
    totalReportPageNumber: 0,
    currentReportPageNumber: 1

};

export const jobPageSlice = createSlice({
  name: 'jobPage',
  initialState,
  reducers: {
    setCurrentPageNumber: (state, action: PayloadAction<number>) => {
        state.currentPageNumber = action.payload;
    },
    setTotalPageNumber: (state, action: PayloadAction<number>) => {
        state.totalPageNumber = action.payload;
    },
    setTotalJobNumber: (state, action: PayloadAction<number>) => {
        state.totalJobNumber = action.payload;
    },
    setJobsPerPage: (state, action: PayloadAction<number>) => {
        state.jobsPerPage = action.payload;
    },

    setTotalRobotJobs: (state, action: PayloadAction<number>) => {
        state.totalRobotJobs = action.payload;
    },
    setTotalRobotJobsPerPage: (state, action: PayloadAction<number>) => {
        state.totalRobotJobsPerPage = action.payload;
    },
    setTotalRobotJobsPageNumber: (state, action: PayloadAction<number>) => {
        state.totalRobotJobsPageNumber = action.payload;
    },
    setCurrentRobotJobsPageNumber: (state, action: PayloadAction<number>) => {
        state.currentRobotJobsPageNumber = action.payload
    },

    setTotalReportNumber: (state, action: PayloadAction<number>) => {
        state.totalReportNumber = action.payload;
    },
    setTotalReportPerPage: (state, action: PayloadAction<number>) => {
        state.totalReportPerPage = action.payload;
    },
    setTotalReportPageNumber: (state, action: PayloadAction<number>) => {
        state.totalReportPageNumber = action.payload;
    },
    setCurrentReportPageNumber: (state, action: PayloadAction<number>) => {
        state.currentReportPageNumber = action.payload;
    }
    },
});

export const { setCurrentPageNumber, setTotalPageNumber, setTotalJobNumber,setJobsPerPage, setTotalRobotJobs, setTotalRobotJobsPerPage, setTotalRobotJobsPageNumber, setCurrentRobotJobsPageNumber, setTotalReportNumber, setTotalReportPerPage, setTotalReportPageNumber, setCurrentReportPageNumber } = jobPageSlice.actions;

export default jobPageSlice.reducer;

// features/map/mapSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FireState {
  fireTrigger: boolean;
  isFireAlarmOn: boolean;
}

const initialState: FireState = {
    fireTrigger: false, 
    isFireAlarmOn: false
};

export const fireTriggerSlice = createSlice({
  name: 'fire',
  initialState,
  reducers: {
    triggerFire: (state) => {
      state.fireTrigger = !state.fireTrigger;
    },
    setModalClose: (state) => {
      state.fireTrigger = false;
    },
    setFireAlarmOn: (state, action: PayloadAction<boolean>) => {
      state.isFireAlarmOn = action.payload
    },
    setFireAlarmOff: (state) => {
      state.isFireAlarmOn = false
    }
  },
});

export const { triggerFire, setModalClose, setFireAlarmOff, setFireAlarmOn } = fireTriggerSlice.actions;

export default fireTriggerSlice.reducer;

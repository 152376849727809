import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface FormData {
    pickUp: string;
    dropOff: string;
    time: string;
    robot: string;
    waitTime: string;
    phoneNumber: string;
    recipient_name: string;
    recipient_location: string;  // Single location to be used in form submission
    compartment_pin: string;
    sender_location: string;
    sender_name: string;
    job_type: string;
    robot_id: string;
    required_compartment: number;
    timestamp: number;
    sender_id: string;
    recipient_id: string;
    organisation_id: string;
}

interface FormState {
    form: FormData;
    recipient_locations: string[];  // Separate array for multiple recipient locations
    isSubmitted: boolean;
    isLoading: boolean;
    error: string | null;
    current_index: number;
}

const initialState: FormState = {
    form: {
        pickUp: '',
        dropOff: '',
        time: '',
        robot: '',
        waitTime: '',
        phoneNumber: '',
        recipient_name: '',
        recipient_location: '',  // Default single location
        compartment_pin: '',
        sender_location: '',
        sender_name: '',
        job_type: '',
        robot_id: '',
        required_compartment: 1,
        timestamp: Date.now(),
        sender_id: '',
        recipient_id: '',
        organisation_id: '',
    },
    recipient_locations: [''],  
    isSubmitted: false,
    isLoading: false,
    error: null,
    current_index: 0,
};


const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        updateField: (state, action: PayloadAction<{ name: string; value: string }>) => {
            const { name, value } = action.payload;
            (state.form as any)[name] = value;
        },
        addRecipientLocation: (state) => {
            if (state.recipient_locations.length < 3) {
                state.recipient_locations.push('');
            }
        },
        updateRecipientLocation: (state, action: PayloadAction<{ index: number; value: string }>) => {
            const { index, value } = action.payload;
            state.recipient_locations[index] = value;
            if ( index === 0 ){
                console.log("Updating recipient_location in form", index, value);
                state.form.recipient_location = value; // Update the recipient_location in the form
            }
        },
        updateRecipientIndex: (state, action: PayloadAction<number>) => {
            state.current_index = action.payload;
        },
        removeRecipientLocation: (state, action: PayloadAction<number>) => {
            if (action.payload > 0) {
                state.recipient_locations.splice(action.payload, 1);
            }
        },
        setRecipientLocationInForm: (state, action: PayloadAction<string>) => {
            state.form.recipient_location = action.payload; // Update the recipient_location in the form
        },
        deleteRecipientLocation: (state) => {
            state.recipient_locations = [''];
        },
    },
});

export const { updateField, addRecipientLocation, updateRecipientLocation, removeRecipientLocation, setRecipientLocationInForm, updateRecipientIndex, deleteRecipientLocation } = formSlice.actions;

export default formSlice.reducer;
